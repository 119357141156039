import React from "react";
import Link from "../misc/link";

const PrivacyPolicyNotice = () => {
    const notice = `
    For information about our collection and use of your personal information, 
    our privacy and security practices and your data protection rights, please see our 
    `;

    return (
        <span className="privacyPolicy">
            {notice}
            <Link to={`/privacy-policy`}>privacy policy</Link>.
        </span>
    );
};

export default PrivacyPolicyNotice;
