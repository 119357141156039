import React, { useRef, useEffect } from "react";

const FilterSearch = ({ filter }) => {
    const { setter, currentValue, placeholder, type = "customer" } = filter;
    const inputEl = useRef(null);

    useEffect(() => {
        if (currentValue !== "") {
            inputEl.current.value = currentValue;
            inputEl.current.focus();
        }
    });

    const handleInputChange = (e) => {
        e.preventDefault();
        setter(e.target.value);
        inputEl.current.focus();
    };

    const CustomersSearchBox = () => {
        return (
            <div className="st-search-box-customers">
                <div className="st-search-box-input-container">
                    <a className="st-plain-button-with-icon st-search-box-customers__focus-button" href="#no-anchor">
                        <div className="st-plain-button-with-icon__content"></div>
                    </a>
                    <input
                        ref={inputEl}
                        id="sourceSearch"
                        className="st-search-box-customers__input"
                        placeholder={placeholder}
                        defaultValue={currentValue}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
        );
    };

    const IntegrationSearchBox = () => {
        return (
            <div className="st-search-box">
                <div className="st-search-box-input-container">
                    <a className="st-plain-button-with-icon st-search-box__focus-button" href="#noanchor">
                        <div className="st-plain-button-with-icon__content"></div>
                    </a>
                    <input
                        ref={inputEl}
                        id="sourceSearch"
                        className="st-search-box__input"
                        placeholder={placeholder}
                        onChange={handleInputChange}
                        defaultValue={currentValue}
                    />
                </div>
            </div>
        );
    };

    return type === "customer" ? <CustomersSearchBox /> : <IntegrationSearchBox />;
};

export default FilterSearch;
