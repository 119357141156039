import Link from "../../../../src/components/misc/link";
import SectionSeparator from "../../../../src/components/layout/section-separator";
import DestinationsBoxGrid from "../../../../src/components/destinations/destinations-box-grid";
import CTASection from "../../../../src/components/misc/cta-section";
import multipleDestinationsIcon from "../../../../src/assets/images/icons/icon-multiple-destinations.svg";
import transparencyIcon from "../../../../src/assets/images/icons/icon-features-auditability.svg";
import securityIcon from "../../../../src/assets/images/icons/icon-features-security.svg";
import assetSources from "../../../../src/assets/images/illustrations/asset-sources.svg";
import assetAnalysisTools from "../../../../src/assets/images/illustrations/asset-analysis-tools.svg";
import * as React from 'react';
export default {
  Link,
  SectionSeparator,
  DestinationsBoxGrid,
  CTASection,
  multipleDestinationsIcon,
  transparencyIcon,
  securityIcon,
  assetSources,
  assetAnalysisTools,
  React
};