import image from "../../../../src/assets/images/illustrations/asset-spool.svg";
import FullLogo from "../../../../src/assets/images/illustrations/stitch-logo-lockup-dl.svg";
import LogoMark from "../../../../src/assets/images/illustrations/stitch-logo-mark-dl.svg";
import Link from "../../../../src/components/misc/link";
import * as React from 'react';
export default {
  image,
  FullLogo,
  LogoMark,
  Link,
  React
};