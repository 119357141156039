import React from "react";
import Link from "../../misc/link";

const CTAThree = ({ title, position }) => {
    const heading = title ?? `Get Started Today!`;
    return (
        <div className="mini-cta compound">
            <div className="trial-cta">
                <p className="st-subheading--alt">{heading}</p>
                <ul>
                    <li>Unlimited data volume during trial</li>
                    <li>Set up in minutes</li>
                </ul>
            </div>
            <div className="trial-button">
                <Link
                    className="st-button"
                    id="signup-button-signup-mini-resource"
                    to={`/signup?utm_source=ctaThree-${position}&utm_medium=cta&utm_campaign=internal&utm_content=${heading}`}
                >
                    Sign up <span className="arrow"></span>
                </Link>
            </div>
        </div>
    );
};

export default CTAThree;
