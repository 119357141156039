import Link from "../../../../src/components/misc/link";
import BGImage from "../../../../src/assets/images/illustrations/cohort-analysis-banner.png";
import image1 from "../../../../src/assets/images/illustrations/2010grads.png";
import image2 from "../../../../src/assets/images/illustrations/Average+Income+class+of+2010+cohort.png";
import image3 from "../../../../src/assets/images/illustrations/Two+cohorts+-+2010+and+2011.png";
import image4 from "../../../../src/assets/images/illustrations/Average+Income+For+2010+vs+2011.png";
import image5 from "../../../../src/assets/images/illustrations/Customer+Spending+By+Referral+Source.png";
import image6 from "../../../../src/assets/images/illustrations/Average+Cumulative+Spending+based+on+1st+Purchase.png";
import image7 from "../../../../src/assets/images/illustrations/Excel+Raw+Data+Screens.png";
import image8 from "../../../../src/assets/images/illustrations/Excel+Combo+Picture.png";
import * as React from 'react';
export default {
  Link,
  BGImage,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  React
};