import React from "react";
import Link from "../../misc/link";

const CTAEight = ({ position }) => {
    return (
        <section className="st-section--lowlight">
            <div className="content">
                <div className="st-callout signup-form">
                    <h2 className="st-heading--2">{`Get more from your data`}</h2>
                    <p className="st-subheading--alt">Deliver data your organization can trust.</p>
                    <br />
                    <div className="trial-button">
                        <Link
                            className="st-button"
                            id="signup-button-signup-mini-resource"
                            to={`https://info.talend.com/request-talend-cloud.html?utm_source=stitch&utm_medium=referral&utm_content=ctaFive-${position}&utm_campaign=internal`}
                        >
                            Get started today <span className="arrow"></span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CTAEight;
