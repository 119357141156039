import React from "react";
import CTAOne from "./cta-variants/cta-one";
import CTATwo from "./cta-variants/cta-two";
import CTAThree from "./cta-variants/cta-three";
import CTAFour from "./cta-variants/cta-four";
import CTAFive from "./cta-variants/cta-five";
import CTASix from "./cta-variants/cta-six";
import CTASeven from "./cta-variants/cta-seven";
import CTAEight from "./cta-variants/cta-eight";
import CTAOneBlack from "./cta-variants/cta-one-black";
import CTATwoBlack from "./cta-variants/cta-two-black";

const CTAVariantMap = {
    ctaOne: CTAOne,
    ctaOneBlack: CTAOneBlack,
    ctaTwo: CTATwo,
    ctaTwoBlack: CTATwoBlack,
    ctaThree: CTAThree,
    ctaFour: CTAFour,
    ctaFive: CTAFive,
    ctaSix: CTASix,
    ctaSeven: CTASeven,
    ctaEight: CTAEight,
};

const CTASelector = ({ variant, title, position }) => {
    const CTAType = CTAVariantMap[variant];
    return <CTAType title={title} position={position} />;
};

export default CTASelector;
