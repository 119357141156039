import ResourcesLogoBoxes from "../../../../src/components/resources/resource-logo-boxes";
import GlossaryList from "../../../../src/components/resources/glossary-list";
import SectionSeparator from "../../../../src/components/layout/section-separator";
import Link from "../../../../src/components/misc/link";
import ImgAssetFeaturesSupport from "../../../../src/assets/images/icons/icon-features-support.svg";
import ImgAssetFeaturesBlog from "../../../../src/assets/images/icons/icon-blog.svg";
import ImgAssetFeaturesChangelog from "../../../../src/assets/images/icons/icon-changelog.svg";
import ImgAssetFeaturesDocs from "../../../../src/assets/images/icons/icon-file.svg";
import * as React from 'react';
export default {
  ResourcesLogoBoxes,
  GlossaryList,
  SectionSeparator,
  Link,
  ImgAssetFeaturesSupport,
  ImgAssetFeaturesBlog,
  ImgAssetFeaturesChangelog,
  ImgAssetFeaturesDocs,
  React
};