import React, { useState } from "react";

const SecurityPolicies = () => {
    const [card, setCard] = useState("card-1");

    const handleCardChange = (e) => {
        setCard(e.target.dataset.id);
    };

    return (
        <section className="st-section policy-lists">
            <div className="card-controler">
                <h2 className="st-heading--2">Our policies</h2>
                <ul className="submenu">
                    <li className="active">
                        <button
                            data-id="card-1"
                            className={`st-button-plain ${card === "card-1" && "active"}`}
                            onClick={handleCardChange}
                        >
                            Application
                        </button>
                    </li>
                    <li>
                        <button
                            data-id="card-2"
                            className={`st-button-plain ${card === "card-2" && "active"}`}
                            onClick={handleCardChange}
                        >
                            Environment
                        </button>
                    </li>
                    <li>
                        <button
                            data-id="card-3"
                            className={`st-button-plain ${card === "card-3" && "active"}`}
                            onClick={handleCardChange}
                        >
                            Data policies
                        </button>
                    </li>
                </ul>
            </div>
            <div className="card-response">
                <div className={`card ${card === "card-1" && "active"}`} id="card-1">
                    <h3 className="st-heading--3 mobile">Application</h3>
                    <ul>
                        <li>
                            Stitch’s web application uses encrypted communication. HSTS is used to ensure browsers
                            always encrypt all communication with Stitch.
                        </li>
                        <li>
                            Stitch’s data source integrations use the minimum permissions that allow read access to
                            necessary data, and can be configured by users to replicate only a subset of available data.
                        </li>
                        <li>
                            Stitch offers secure options for making connections to all data sources and destinations,
                            including SSH tunneling, SSL/TLS, and IP whitelisting. Stitch exclusively uses HTTPS for
                            web-based data sources.
                        </li>
                        <li>
                            Stitch provides direct access to logs from data source integrations for auditing, and sends
                            notifications to users when error conditions are encountered.
                        </li>
                        <li>
                            Stitch only retains your data long enough to ensure it's moved successfully into your
                            destinations.
                        </li>
                    </ul>
                </div>
                <div className={`card ${card === "card-2" && "active"}`} id="card-2">
                    <h3 className="st-heading--3 mobile">Environment</h3>
                    <ul>
                        <li>
                            Stitch’s servers are hosted in Amazon Web Services, which provides assurances for their
                            physical and virtualized computing environments including SOC 1, 2, and 3, and ISO/IEC
                            27001.
                        </li>
                        <li>
                            Stitch operates within an Amazon Virtual Private Cloud (VPC), with subnets segregated by
                            security level, and firewalls configured to restrict network access.
                        </li>
                        <li>
                            Stitch regularly performs automated vulnerability scans and installs security updates and
                            patches.
                        </li>
                        <li>
                            Stitch’s application and environment is regularly audited by third-party security
                            professionals conducting specialized penetration tests.
                        </li>
                    </ul>
                </div>
                <div className={`card ${card === "card-3" && "active"}`} id="card-3">
                    <h3 className="st-heading--3 mobile">Data policies</h3>
                    <ul>
                        <li>
                            Stitch classifies your data and credentials as our most critical assets. We strictly control
                            access to data and credentials and require them to be encrypted using industry-standard
                            methods both at rest and in transit within our environment
                        </li>
                        <li>
                            Stitch educates employees about their role in keeping customer data safe, and mandates
                            policies that protect your data.
                        </li>
                        <li>
                            Stitch monitors application, system, and data access logs within its production environment
                            for anomalous behavior.
                        </li>
                        <li>
                            Stitch maintains documented policies and procedures for handling security incidents, which
                            include timely notification to affected customers in case of a verified data breach.
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default SecurityPolicies;
