import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "../misc/link";

const PartnerBoxGrid = ({ category = null }) => {
    const { partners } = useStaticQuery(graphql`
        query PartnersQuery {
            partners: allContentfulPartner(sort: { fields: order, order: ASC }) {
                nodes {
                    url
                    name
                    partner_category {
                        name
                    }
                    logo {
                        file {
                            url
                        }
                        title
                    }
                    order
                }
            }
        }
    `);

    const allPartners = partners.nodes;
    const uniqueCategories = [...new Set(allPartners.map((partner) => partner.partner_category.name))];

    const filteredPartners =
        category && uniqueCategories.includes(category)
            ? allPartners.filter((partner) => partner.partner_category.name === category)
            : allPartners;

    return (
        <ul className="st-logo-grid">
            {filteredPartners.map((partner) => (
                <li className="st-logo-grid-item">
                    <Link
                        to={partner.url}
                        title={`Learn more about ${partner.name} on their website`}
                        className={`st-logo-grid-item__box`}
                    >
                        <img src={partner.logo.file.url} alt={partner.logo.title} />
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export default PartnerBoxGrid;
