import { useState, useEffect } from "react";

export const useMarketoForm = ({ formId, callback }) => {
    const baseUrl = `${process.env.GATSBY_MARKETO_BASE_URL}`;
    const munchkinId = `${process.env.GATSBY_MARKETO_MUNCHKIN_ID}`;
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        if (scriptLoaded) {
            window.MktoForms2.loadForm(baseUrl, munchkinId, formId, callback);
        } else {
            appendScript(baseUrl, setScriptLoaded);
        }
    }, [scriptLoaded, baseUrl, munchkinId, formId, callback]);
};

const appendScript = (baseUrl, setScriptLoaded) => {
    if (window.MktoForms2) return setScriptLoaded(true);

    const script = document.createElement("script");
    script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
    script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null);
    document.body.appendChild(script);
};
