import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "../misc/link";

const DestinationsBoxGrid = ({ integrationUrl = null }) => {
    const path = integrationUrl ? `${integrationUrl}` : `/data-warehouses/`;

    const { warehouses } = useStaticQuery(graphql`
        query WarehousingQuery {
            warehouses: allContentfulDataEndpoint(filter: { hidden: { ne: true } }, sort: { fields: url, order: ASC }) {
                nodes {
                    contentful_id
                    name
                    url
                    status
                    square_logo {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);

    const destinations = warehouses.nodes;

    return (
        <div className="st-section st-section--no-margin">
            <ul className="st-logo-boxes">
                {destinations.map((destination, i) => (
                    <li key={i} className="logo-box">
                        <Link className="logo-box-border" to={`${path}${destination.url}/`}>
                            <div className="logo-info">
                                <img src={destination.square_logo.file.url} alt={destination.name} />
                                <p>{destination.name}</p>
                                {destination.status !== "Available" ? <p>{destination.status}</p> : ""}
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DestinationsBoxGrid;
