import React from "react";
import Link from "../../misc/link";

const CTAFive = ({ title, position }) => {
    const heading = title ?? `Extract data from the sources that run your business`;
    return (
        <div className="mini-cta yellow">
            <div className="lines">&nbsp;</div>
            <div className="trial-cta">
                <p className="st-subheading">{heading}</p>
                <p className="st-subheading2">Free 14-day trial. No credit card required</p>
            </div>
            <div className="trial-button">
                <Link
                    className="st-button"
                    id="signup-button-signup-mini-resource"
                    to={`/signup?utm_source=ctaFive-${position}&utm_medium=cta&utm_campaign=internal&utm_content=${heading}`}
                >
                    Sign up <span className="arrow"></span>
                </Link>
            </div>
        </div>
    );
};

export default CTAFive;
