import Link from "../../../../src/components/misc/link";
import AssetDist1 from "../../../../src/assets/images/illustrations/guide-icon-columnar-8.svg";
import AssetDist2 from "../../../../src/assets/images/illustrations/guide-icon-compression-9.svg";
import AssetDist3 from "../../../../src/assets/images/illustrations/guide-icon-distributed-10.svg";
import Diagram from "../../../../src/assets/images/illustrations/guide-HadoopDiagram.svg";
import * as React from 'react';
export default {
  Link,
  AssetDist1,
  AssetDist2,
  AssetDist3,
  Diagram,
  React
};