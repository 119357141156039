import Link from "../../../../src/components/misc/link";
import DualCTA from "../../../../src/components/misc/dual-cta";
import clv1 from "../../../../src/assets/images/illustrations/clv1.png";
import clv2 from "../../../../src/assets/images/illustrations/clv2.png";
import clv3 from "../../../../src/assets/images/illustrations/clv3.png";
import * as React from 'react';
export default {
  Link,
  DualCTA,
  clv1,
  clv2,
  clv3,
  React
};