import React, { useEffect } from "react";
import Link from "../misc/link";
import { headerMenu } from "../../../data/menus";
import StitchLogoFull from "../../assets/svg/stitch-logo-full.svg";

const toggleMenu = () => {
    document.getElementById("nav-toggle").checked = false;
};

const MainNavigation = () => {
    useEffect(() => {
        const handleResize = () => {
            // If mobile nav happens to be open.
            // Close it automagically at this breakpoint
            if (window.innerWidth > 970) {
                toggleMenu();
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    return (
        <nav className="primary" data-main-menu role="navigation">
            <input type="checkbox" id="nav-toggle" aria-labelledby="nav-toggle" className="nav-toggle hidden"></input>
            <ul className="st-inline-nav logo">
                <li className="logo-item">
                    <Link to={"/"}>
                        <StitchLogoFull />
                    </Link>
                </li>
            </ul>
            <ul className="st-inline-nav menu">
                {headerMenu.map((item, i) => {
                    if (item?.items) {
                        return (
                            <li key={i} className={`nav-item dropdown heading ${item.heading.toLowerCase()}-tree`}>
                                <a className="st-button-plain" href="#no-anchor">
                                    {item.heading}
                                </a>
                                <ul className="dropdown-popup" aria-label="submenu">
                                    {item.items.map(({ href, label }) => (
                                        <li key={label}>
                                            <Link to={href} className={`dropdown-item`} onClick={toggleMenu}>
                                                {label}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        );
                    } else {
                        return (
                            <li key={item.label} className={`nav-item ${item.label.toLowerCase()}-tree`}>
                                <Link
                                    className={item.className ?? "st-button-plain"}
                                    to={item.href}
                                    onClick={toggleMenu}
                                >
                                    {item.label}
                                </Link>
                            </li>
                        );
                    }
                })}
            </ul>
            <label htmlFor="nav-toggle" className="nav-toggle-label">
                <span></span>
            </label>
        </nav>
    );
};

export default MainNavigation;
