import Link from "../../../../src/components/misc/link";
import CTASection from "../../../../src/components/misc/cta-section";
import SectionSeparator from "../../../../src/components/layout/section-separator";
import SecurityPolicies from "../../../../src/components/platform/security-policies";
import MoreFeatures from "../../../../src/components/platform/more-features";
import AssetSoc2 from "../../../../src/assets/images/illustrations/asset-soc2.svg";
import AssetHipaa from "../../../../src/assets/images/illustrations/asset-hipaa.svg";
import AssetGdpr from "../../../../src/assets/images/illustrations/asset-gdpr.svg";
import * as React from 'react';
export default {
  Link,
  CTASection,
  SectionSeparator,
  SecurityPolicies,
  MoreFeatures,
  AssetSoc2,
  AssetHipaa,
  AssetGdpr,
  React
};