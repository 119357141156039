import Link from "../../../../src/components/misc/link";
import MapSection from "../../../../src/components/misc/map-section";
import SectionSeparator from "../../../../src/components/layout/section-separator";
import CTASection from "../../../../src/components/misc/cta-section";
import CTAButton from "../../../../src/components/misc/cta-button";
import JobImage1 from "../../../../src/assets/images/team/jobs-1a.jpg";
import JobImage2 from "../../../../src/assets/images/team/jobs-2.jpg";
import JobImage3 from "../../../../src/assets/images/team/jobs-3.jpg";
import JobImage4 from "../../../../src/assets/images/team/jobs-4.jpg";
import JobSkyline from "../../../../src/assets/images/illustrations/asset_phl_skyline.svg";
import JobProcessImage from "../../../../src/assets/images/team/process.svg";
import JobImage5 from "../../../../src/assets/images/team/jobs-5.jpg";
import JobImage6 from "../../../../src/assets/images/team/jobs-6.jpg";
import * as React from 'react';
export default {
  Link,
  MapSection,
  SectionSeparator,
  CTASection,
  CTAButton,
  JobImage1,
  JobImage2,
  JobImage3,
  JobImage4,
  JobSkyline,
  JobProcessImage,
  JobImage5,
  JobImage6,
  React
};