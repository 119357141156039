import SectionSeparator from "../../../../src/components/layout/section-separator";
import CustomerLogobar from "../../../../src/components/customer/customer-logo-bar";
import ImgAssetFeaturesSources from "../../../../src/assets/images/illustrations/asset-features-sources.svg";
import ImgAssetFeaturesDestinations from "../../../../src/assets/images/illustrations/asset-features-destinations.svg";
import ImgAssetFeaturesTools from "../../../../src/assets/images/illustrations/asset-features-tools.svg";
import ImgAssetCustomerLogo from "../../../../src/assets/images/customers/indiegogo_BLK.svg";
import ImgAssetFeaturesExtensibility from "../../../../src/assets/images/icons/icon-features-extensibility.svg";
import ImgAssetFeaturesSecurity from "../../../../src/assets/images/icons/icon-features-security.svg";
import ImgAssetFeaturesScalability from "../../../../src/assets/images/icons/icon-features-scalability.svg";
import * as React from 'react';
export default {
  SectionSeparator,
  CustomerLogobar,
  ImgAssetFeaturesSources,
  ImgAssetFeaturesDestinations,
  ImgAssetFeaturesTools,
  ImgAssetCustomerLogo,
  ImgAssetFeaturesExtensibility,
  ImgAssetFeaturesSecurity,
  ImgAssetFeaturesScalability,
  React
};