import React from "react";
import DualCTA from "./dual-cta";

const CTASection = ({
    heading,
    subheading,
    containerClasses = "",
    dualCTA = false,
    dualCTADescription = true,
    children,
}) => {
    return (
        <section className={containerClasses}>
            <div className="content">
                <div className="st-callout">
                    {heading && <h2 className="st-heading--2">{heading}</h2>}

                    {subheading && <p className="st-subheading--alt">{subheading}</p>}
                    {dualCTA ? <DualCTA description={dualCTADescription} /> : children}
                </div>
            </div>
        </section>
    );
};

export default CTASection;
