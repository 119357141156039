import React, { useState, useEffect } from "react";
import Link from "../misc/link";
import {
    connectAPIPostSnippet,
    stitchOAuthJSSnippet,
    API1B_Snippet,
    API2A_Snippet,
    API2B_Snippet,
    API3A_Snippet,
    API3B_Snippet,
    JS1A_Snippet,
    JS2A_Snippet,
    JS3A_Snippet,
} from "../../../data/code-snippet";
import imageAssetApiAuth from "../../assets/images/illustrations/asset-api-auth.svg";
import imageAssetApiFieldSelection from "../../assets/images/illustrations/asset-api-field-selection.svg";
import imageAssetApiConnectionTest from "../../assets/images/illustrations/asset-api-connection-test.svg";
import CodeBlock from "../misc/code-block";

const ApiExamples = () => {
    const [type, setType] = useState("api"); // "api" or "js"
    const [method, setMethod] = useState("a"); // "a" or "b"
    const [listNr, setListNr] = useState("1"); // "1" or "2" or "3"
    const [snippet, setSnippet] = useState("api-a-1");

    const handleTypeChange = (e) => {
        const value = e.target.dataset.type ?? "api";
        setType(value);
    };

    const handleMethodChange = (e) => {
        const value = e.target.dataset.method ?? "a";
        setMethod(value);
    };

    const handleListNrChange = (e) => {
        const value = e.target.dataset.list ?? "1";
        setListNr(value);
    };
    // if type changes, reset method and listNr
    useEffect(() => {
        setMethod("a");
        setListNr("1");
    }, [type]);

    // if listNr changes, reset method
    useEffect(() => {
        setMethod("a");
    }, [listNr]);

    // set snippet visibility
    useEffect(() => {
        setSnippet(`${type}-${method}-${listNr}`);
    }, [type, method, listNr]);

    return (
        <>
            <section className="st-section api-examples">
                <div className="st-callout">
                    <h2 className="st-heading--2">Stitch Connect components</h2>
                    <p className="st-subheading">
                        Work with the API directly, or use the JavaScript client to create and access Stitch client
                        accounts, extract data, and load data into warehouses.
                    </p>
                    <pre>{`${type}-${method}-${listNr}`}</pre>
                    <div className="callout-content mobile">
                        <h3 className="st-heading--3">Connect API</h3>
                        <p>For example, this is how you would create a Stitch client using the Connect API:</p>
                        <div className="st-code">
                            <CodeBlock id={`code-snippet`} className={`language-shell`}>
                                {connectAPIPostSnippet}
                            </CodeBlock>
                        </div>
                        <Link to="https://www.stitchdata.com/docs/stitch-connect/api">Expore the API docs</Link>
                        <h3 className="st-heading--3">JS Client</h3>
                        <p>This is how you would authorize an integration with OAuth using the JS Client:</p>
                        <div className="st-code">
                            <CodeBlock id={`code-snippet`} className={`language-js`}>
                                {stitchOAuthJSSnippet}
                            </CodeBlock>
                        </div>
                        <Link to="https://www.stitchdata.com/docs/stitch-connect/javascript-client">
                            Explore the JS Client docs
                        </Link>
                    </div>
                </div>
                <div className="callout-content desktop">
                    <div className="st-button-set">
                        <button
                            data-type="api"
                            className={`st-button ${type === "api" && "highlight"}`}
                            onClick={handleTypeChange}
                        >
                            Connect API
                        </button>
                        <button
                            data-type="js"
                            className={`st-button ${type === "js" && "highlight"}`}
                            onClick={handleTypeChange}
                        >
                            JS Client
                        </button>
                    </div>
                    <ul className={`connect-list api-list ${type === "api" && "visible"}`}>
                        <li className={listNr === "1" ? "active" : ""}>
                            <span onMouseDown={handleListNrChange} role="presentation" data-list="1">
                                Create a Stitch client
                            </span>
                            <div className="button-wrapper">
                                <button
                                    className={`st-button-plain ${method === "a" && "highlight"}`}
                                    data-method="a"
                                    onClick={handleMethodChange}
                                >
                                    POST
                                </button>
                                <button
                                    className={`st-button-plain ${method === "b" && "highlight"}`}
                                    data-method="b"
                                    onClick={handleMethodChange}
                                >
                                    Response
                                </button>
                            </div>
                        </li>
                        <li className={listNr === "2" ? "active" : ""}>
                            <span onMouseDown={handleListNrChange} role="presentation" data-list="2">
                                Create a HubSpot source
                            </span>
                            <div className="button-wrapper">
                                <button
                                    className={`st-button-plain ${method === "a" && "highlight"}`}
                                    data-method="a"
                                    onClick={handleMethodChange}
                                >
                                    POST
                                </button>
                                <button
                                    className={`st-button-plain ${method === "b" && "highlight"}`}
                                    data-method="b"
                                    onClick={handleMethodChange}
                                >
                                    Response
                                </button>
                            </div>
                        </li>
                        <li className={listNr === "3" ? "active" : ""}>
                            <span onMouseDown={handleListNrChange} role="presentation" data-list="3">
                                Update a destination
                            </span>
                            <div className="button-wrapper">
                                <button
                                    className={`st-button-plain ${method === "a" && "highlight"}`}
                                    data-method="a"
                                    onClick={handleMethodChange}
                                >
                                    PUT
                                </button>
                                <button
                                    className={`st-button-plain ${method === "b" && "highlight"}`}
                                    data-method="b"
                                    onClick={handleMethodChange}
                                >
                                    Response
                                </button>
                            </div>
                        </li>
                        <a className="border-top" href="https://www.stitchdata.com/docs/stitch-connect/api">
                            Explore the API docs
                        </a>
                    </ul>
                    <ul className={`connect-list js-list ${type === "js" && "visible"}`}>
                        <li className={listNr === "1" ? "active" : ""}>
                            <span onMouseDown={handleListNrChange} data-list="1" role="presentation">
                                Authorize an integration with OAuth
                            </span>
                            <div className="button-wrapper">
                                <button
                                    className={`st-button-plain ${method === "a" && "highlight"}`}
                                    data-method="a"
                                    onClick={handleMethodChange}
                                >
                                    Function
                                </button>
                                <button
                                    className={`st-button-plain ${method === "b" && "highlight"}`}
                                    data-method="b"
                                    onClick={handleMethodChange}
                                >
                                    Result
                                </button>
                            </div>
                        </li>
                        <li className={listNr === "2" ? "active" : ""}>
                            <span onMouseDown={handleListNrChange} data-list="2" role="presentation">
                                Select data from an integration
                            </span>
                            <div className="button-wrapper">
                                <button
                                    className={`st-button-plain ${method === "a" && "highlight"}`}
                                    data-method="a"
                                    onClick={handleMethodChange}
                                >
                                    Function
                                </button>
                                <button
                                    className={`st-button-plain ${method === "b" && "highlight"}`}
                                    data-method="b"
                                    onClick={handleMethodChange}
                                >
                                    Result
                                </button>
                            </div>
                        </li>
                        <li className={listNr === "3" ? "active" : ""}>
                            <span onMouseDown={handleListNrChange} data-list="3" role="presentation">
                                Display a connection check
                            </span>
                            <div className="button-wrapper">
                                <button
                                    className={`st-button-plain ${method === "a" && "highlight"}`}
                                    data-method="a"
                                    onClick={handleMethodChange}
                                >
                                    Function
                                </button>
                                <button
                                    className={`st-button-plain ${method === "b" && "highlight"}`}
                                    data-method="b"
                                    onClick={handleMethodChange}
                                >
                                    Result
                                </button>
                            </div>
                        </li>
                        <a
                            className="border-top"
                            href="https://www.stitchdata.com/docs/stitch-connect/javascript-client"
                        >
                            Explore the JS Client docs
                        </a>
                    </ul>
                </div>
                <div className="callout-decor desktop">
                    <div className="st-code">
                        <div className={`picker-response ${snippet === "api-a-1" && "visible"}`} id="api-a-1">
                            <div className="st-code">
                                <CodeBlock id={`code-snippet`} className={`language-shell`}>
                                    {connectAPIPostSnippet}
                                </CodeBlock>
                            </div>
                        </div>
                        <div className={`picker-response ${snippet === "api-b-1" && "visible"}`} id="api-b-1">
                            <div className="st-code">
                                <CodeBlock id={`code-snippet`} className={`language-shell`}>
                                    {API1B_Snippet}
                                </CodeBlock>
                            </div>
                        </div>
                        <div className={`picker-response ${snippet === "api-a-2" && "visible"}`} id="api-a-2">
                            <div className="st-code">
                                <CodeBlock id={`code-snippet`} className={`language-shell`}>
                                    {API2A_Snippet}
                                </CodeBlock>
                            </div>
                        </div>
                        <div className={`picker-response ${snippet === "api-b-2" && "visible"}`} id="api-b-2">
                            <div className="st-code">
                                <CodeBlock id={`code-snippet`} className={`language-shell`}>
                                    {API2B_Snippet}
                                </CodeBlock>
                            </div>
                        </div>
                        <div className={`picker-response ${snippet === "api-a-3" && "visible"}`} id="api-a-3">
                            <div className="st-code">
                                <CodeBlock id={`code-snippet`} className={`language-shell`}>
                                    {API3A_Snippet}
                                </CodeBlock>
                            </div>
                        </div>
                        <div className={`picker-response ${snippet === "api-b-3" && "visible"}`} id="api-b-3">
                            <div className="st-code">
                                <CodeBlock id={`code-snippet`} className={`language-shell`}>
                                    {API3B_Snippet}
                                </CodeBlock>
                            </div>
                        </div>
                        <div className={`picker-response ${snippet === "js-a-1" && "visible"}`} id="js-a-1">
                            <div className="st-code">
                                <CodeBlock id={`code-snippet`} className={`language-js`}>
                                    {JS1A_Snippet}
                                </CodeBlock>
                            </div>
                        </div>
                        <div className={`picker-response ${snippet === "js-b-1" && "visible"}`} id="js-b-1">
                            <div className="st-code">
                                <img src={imageAssetApiAuth} alt="Authorize an integration with OAuth - Result" />
                            </div>
                        </div>
                        <div className={`picker-response ${snippet === "js-a-2" && "visible"}`} id="js-a-2">
                            <div className="st-code">
                                <CodeBlock id={`code-snippet`} className={`language-js`}>
                                    {JS2A_Snippet}
                                </CodeBlock>
                            </div>
                        </div>
                        <div className={`picker-response ${snippet === "js-b-2" && "visible"}`} id="js-b-2">
                            <div className="st-code">
                                <img src={imageAssetApiFieldSelection} alt="Select data from an integration - Result" />
                            </div>
                        </div>
                        <div className={`picker-response ${snippet === "js-a-3" && "visible"}`} id="js-a-3">
                            <div className="st-code">
                                <CodeBlock id={`code-snippet`} className={`language-js`}>
                                    {JS3A_Snippet}
                                </CodeBlock>
                            </div>
                        </div>
                        <div className={`picker-response ${snippet === "js-b-3" && "visible"}`} id="js-b-3">
                            <div className="st-code">
                                <img src={imageAssetApiConnectionTest} alt="Display a connection check - Result" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ApiExamples;
