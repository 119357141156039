import Link from "../../../../src/components/misc/link";
import CTASection from "../../../../src/components/misc/cta-section";
import SectionSeparator from "../../../../src/components/layout/section-separator";
import MoreFeatures from "../../../../src/components/platform/more-features";
import ImgAssetFeaturesEcoSystem from "../../../../src/assets/images/illustrations/asset-features-ecosystem.svg";
import logoAutopilot from "../../../../src/assets/images/logos/autopilot_logo_240x90_web.svg";
import logoBraintree from "../../../../src/assets/images/logos/braintree_logo_240x90_web.svg";
import logoFreshdesk from "../../../../src/assets/images/logos/freshdesk_logo_240x90_web.png";
import logoSalesforce from "../../../../src/assets/images/logos/salesforce_marketing_cloud_logo_240x90_web.svg";
import { extensibilityCodeSnippet } from "../../../../data/code-snippet";
import * as React from 'react';
export default {
  Link,
  CTASection,
  SectionSeparator,
  MoreFeatures,
  ImgAssetFeaturesEcoSystem,
  logoAutopilot,
  logoBraintree,
  logoFreshdesk,
  logoSalesforce,
  extensibilityCodeSnippet,
  React
};