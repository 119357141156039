import React from "react";
import Link from "../../misc/link";

const CTASeven = ({ title }) => {
    const heading = title ?? `Try Stitch for free, for 14 days`;
    return (
        <section className="st-section" id="trial">
            <div className="trial-cta">
                <div className="trial-cta">
                    <p className="st-subheading">{heading}</p>
                    <ul>
                        <li>Unlimited data volume during trial</li>
                        <li>Set up in minutes</li>
                    </ul>
                </div>
                <div className="trial-button">
                    <Link to="/signup" className="st-button" id="signup-button-signup-trial">
                        Start Your Free Trial<span className="arrow"></span>
                    </Link>
                    <p>No credit card required!</p>
                </div>
            </div>
        </section>
    );
};

export default CTASeven;
