import Link from "../../../../src/components/misc/link";
import AssetSecurity from "../../../../src/assets/images/illustrations/guide-icon-security-6.svg";
import AssetFlexibility from "../../../../src/assets/images/illustrations/guide-icon-flexibility-5.svg";
import AssetAuditability from "../../../../src/assets/images/illustrations/guide-icon-auditability-4.svg";
import AssetScalability from "../../../../src/assets/images/illustrations/guide-icon-scalability-3.svg";
import AssetCDC from "../../../../src/assets/images/illustrations/guide-icon-cdc-2.svg";
import AssetRealtime from "../../../../src/assets/images/illustrations/guide-icon-realtime-1.svg";
import AssetMaintinence from "../../../../src/assets/images/illustrations/guide-icon-maintenance-7.svg";
import * as React from 'react';
export default {
  Link,
  AssetSecurity,
  AssetFlexibility,
  AssetAuditability,
  AssetScalability,
  AssetCDC,
  AssetRealtime,
  AssetMaintinence,
  React
};