import React from "react";
import DualCTA from "../../misc/dual-cta";
import image from "../../../assets/images/illustrations/stitch_data_flow.png";

const CTASix = ({ title }) => {
    const heading = title ?? `Extract data from the sources that run your business`;
    return (
        <section className="st-etl-signup">
            <div className="content">
                <div className="st-callout signup-form">
                    <img src={image} style={{ maxWidth: "80%", paddingTop: "6px" }} alt="" />
                    <h2 className="st-heading--3">START NOW</h2>
                    <p className="st-subheading--alt">{heading}</p>
                    <DualCTA description={false} />
                </div>
            </div>
        </section>
    );
};

export default CTASix;
