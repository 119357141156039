import React from "react";
import DualCTA from "../../misc/dual-cta";

const CTATwoBlack = ({ title }) => {
    const heading = title ?? `Get Started Today!`;
    return (
        <div className="mini-cta full-width black">
            <p className="st-subheading--alt">{heading}</p>
            <DualCTA description={false} />
        </div>
    );
};

export default CTATwoBlack;
