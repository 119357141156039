import Link from "../../../../src/components/misc/link";
import BGImage from "../../../../src/assets/images/illustrations/columnardatabase-banner.jpg";
import image1 from "../../../../src/assets/images/illustrations/640px-Seagate_ST33232A_hard_disk_head_and_platters_detail.jpg";
import image2 from "../../../../src/assets/images/illustrations/purchase-table.png";
import * as React from 'react';
export default {
  Link,
  BGImage,
  image1,
  image2,
  React
};