import React from "react";
import DualCTA from "../../misc/dual-cta";

const CTATwo = ({ title }) => {
    const heading = title ?? `Get Started Today!`;
    return (
        <div className="mini-cta full-width">
            <p className="st-subheading--alt">{heading}</p>
            <DualCTA description={false} />
        </div>
    );
};

export default CTATwo;
