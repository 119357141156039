import React from "react";

const LeftRightSection = ({ containerClasses, image, imageAlt, headline, paragraph, textLocation = "left" }) => {
    const ImageSection = (
        <div className="st-col col-image">
            <img src={image} alt={imageAlt} className="st-section-img" />
        </div>
    );

    const TextSection = (
        <div className="st-col col-text">
            <h2 className="st-heading--2">{headline}</h2>
            <p>{paragraph}</p>
        </div>
    );

    return (
        <section className={`st-section st-row ${containerClasses}`}>
            {textLocation === "left" ? (
                <>
                    {TextSection}
                    {ImageSection}
                </>
            ) : (
                <>
                    {ImageSection}
                    {TextSection}
                </>
            )}
        </section>
    );
};

export default LeftRightSection;
