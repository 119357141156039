import SectionSeparator from "../../../../src/components/layout/section-separator";
import CTASection from "../../../../src/components/misc/cta-section";
import AnalysisToolsGrid from "../../../../src/components/analysis-tools/analysis-tools-grid";
import IntegrationsFilter from "../../../../src/components/integrations/integrations-filter";
import Link from "../../../../src/components/misc/link";
import historicalFeatureIcon from "../../../../src/assets/images/icons/icon-features-historical.svg";
import tableSelectionIcon from "../../../../src/assets/images/icons/icon-table-selection.svg";
import userProfileIcon from "../../../../src/assets/images/icons/icon-user-profile.svg";
import featureExtensibilityIcon from "../../../../src/assets/images/icons/icon-features-extensibility.svg";
import assetSources from "../../../../src/assets/images/illustrations/asset-sources.svg";
import assetAnalysisTools from "../../../../src/assets/images/illustrations/asset-analysis-tools.svg";
import * as React from 'react';
export default {
  SectionSeparator,
  CTASection,
  AnalysisToolsGrid,
  IntegrationsFilter,
  Link,
  historicalFeatureIcon,
  tableSelectionIcon,
  userProfileIcon,
  featureExtensibilityIcon,
  assetSources,
  assetAnalysisTools,
  React
};