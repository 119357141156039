import React from "react";
import Link from "./link";

const PlanComparisonTable = () => {
    return (
        <section className="st-section" id="features">
            <p className="st-subheading" id="compare-plans">
                Compare plan features:
            </p>
            <table className="plan-comparison-table">
                <tbody>
                    <tr>
                        <th className="plan-comparison-table-cell-header">&nbsp;</th>
                        <th className="plan-comparison-table-cell-header">Standard</th>
                        <th className="plan-comparison-table-cell-header">Advanced</th>
                        <th className="plan-comparison-table-cell-header">Premium</th>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell">Rows</td>
                        <td className="plan-comparison-table-cell">5-300 million rows/month</td>
                        <td className="plan-comparison-table-cell">100 million rows/month</td>
                        <td className="plan-comparison-table-cell">1 billion rows/month</td>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell">Destinations</td>
                        <td className="plan-comparison-table-cell">1</td>
                        <td className="plan-comparison-table-cell">3</td>
                        <td className="plan-comparison-table-cell">5</td>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell">Sources</td>
                        <td className="plan-comparison-table-cell">10 (Standard sources)</td>
                        <td className="plan-comparison-table-cell">Unlimited (Enterprise sources)</td>
                        <td className="plan-comparison-table-cell">Unlimited (Enterprise sources)</td>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell">Users</td>
                        <td className="plan-comparison-table-cell">5</td>
                        <td className="plan-comparison-table-cell">Unlimited</td>
                        <td className="plan-comparison-table-cell">Unlimited</td>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell">Historical Sync</td>
                        <td className="plan-comparison-table-cell">7 days</td>
                        <td className="plan-comparison-table-cell">7 days</td>
                        <td className="plan-comparison-table-cell">7 days</td>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell">Extraction Log Retention</td>
                        <td className="plan-comparison-table-cell">7 days</td>
                        <td className="plan-comparison-table-cell">60 days</td>
                        <td className="plan-comparison-table-cell">60 days</td>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell">SOC 2 Type II and ISO 27001 compliance</td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green"></div>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green"></div>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green"></div>
                        </td>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell">
                            Advanced Features
                            <ul>
                                <li>
                                    <Link to={"https://www.stitchdata.com/docs/developers/webhooks/post-load-webhooks"}>
                                        Post-load webhooks
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={
                                            "https://www.stitchdata.com/docs/replication/replication-scheduling/advanced-scheduling"
                                        }
                                    >
                                        Advanced scheduling
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={
                                            "https://www.stitchdata.com/docs/account-security/notifications/extend-email-notifications"
                                        }
                                    >
                                        Notification extensibility
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"https://www.stitchdata.com/docs/developers/stitch-connect/api"}>
                                        Connect API access
                                    </Link>
                                </li>
                            </ul>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green none"></div>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green"></div>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green"></div>
                        </td>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell">Add-on rows</td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green none"></div>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green"></div>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green"></div>
                        </td>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell">Add-on destinations</td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green none"></div>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green"></div>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green"></div>
                        </td>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell">Global Customer Success Manager</td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green none"></div>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green"></div>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green"></div>
                        </td>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell">HIPAA (signing of BAA)</td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green none"></div>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green none"></div>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green"></div>
                        </td>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell">
                            Advanced Connectivity
                            <ul>
                                <li>
                                    <Link to={"https://www.stitchdata.com/docs/security/overview"}>
                                        Site-to-site VPN
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"https://www.stitchdata.com/docs/security/data-encryption"}>
                                        AWS private link
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={
                                            "https://www.stitchdata.com/docs/security/data-encryption/setting-up-reverse-ssh-tunnel"
                                        }
                                    >
                                        Reverse SSH tunnel
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={
                                            "https://www.stitchdata.com/docs/security/data-encryption/setting-up-ssh-tunnel-for-amazon-web-services"
                                        }
                                    >
                                        VPC peering (AWS)
                                    </Link>
                                </li>
                            </ul>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green none"></div>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green none"></div>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <div className="circle-green"></div>
                        </td>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell"><a href="https://www.talend.com/technical-support/support-statements/">Global Support</a></td>
                        <td className="plan-comparison-table-cell">Chat, Portal</td>
                        <td className="plan-comparison-table-cell">Gold</td>
                        <td className="plan-comparison-table-cell">Platinum</td>
                    </tr>
                    <tr>
                        <td className="plan-comparison-table-cell">&nbsp;</td>
                        <td className="plan-comparison-table-cell">
                            <Link
                                to={"https://app.stitchdata.com/signup"}
                                id="signup-button-signup-paid"
                                className="st-button-cta-pri"
                            >
                                Start free trial <span className="arrow"></span>
                            </Link>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <Link
                                to={"/contact-advanced"}
                                id="contact-button-signup-enterprise"
                                className="st-button-cta-pri"
                            >
                                Contact sales <span className="arrow"></span>
                            </Link>
                        </td>
                        <td className="plan-comparison-table-cell">
                            <Link
                                to={"/contact-premium"}
                                id="contact-button-signup-enterprise"
                                className="st-button-cta-pri"
                            >
                                Contact sales <span className="arrow"></span>
                            </Link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    );
};

export default PlanComparisonTable;
