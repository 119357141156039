import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const ResourcesLogoBoxes = () => {
    const { resources, miniResources } = useStaticQuery(graphql`
        query ResourceQuery {
            resources: allContentfulResource(sort: { order: DESC, fields: id }) {
                nodes {
                    id
                    name
                    overview
                    url
                    resource_category {
                        name
                    }
                }
            }
            miniResources: allContentfulMiniResource(sort: { order: DESC, fields: id }) {
                nodes {
                    id
                    name
                    overview
                    slug
                    resource_category {
                        name
                    }
                }
            }
        }
    `);

    const uniqueResources = resources.nodes.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);

    const uniqueMiniResources = miniResources.nodes.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);

    const allResources = [...uniqueResources, ...uniqueMiniResources];

    return (
        <section className="st-section resources">
            <ul className="st-logo-boxes">
                {allResources.map((resource) => {
                    const url = resource.url ? resource.url : `/resources/${resource.slug}`;
                    return (
                        <li className="logo-box" key={resource.id}>
                            {resource.resource_category && (
                                <Link to={url} className={`logo-box-border ${resource.resource_category.name}`}>
                                    <p className="resource-category">{resource.resource_category.name}</p>
                                    <p className="st-subheading resource-heading">{resource.name}</p>
                                    <p className="resource-subheading">{resource.overview}</p>
                                    <p className="resource-link">Read now &rarr;</p>
                                </Link>
                            )}
                            {!resource.resource_category && (
                                <Link to={url} className={`logo-box-border general`}>
                                    <p className="resource-category">General</p>
                                    <p className="st-subheading resource-heading">{resource.name}</p>
                                    <p className="resource-subheading">{resource.overview}</p>
                                    <p className="resource-link">Read now &rarr;</p>
                                </Link>
                            )}
                        </li>
                    );
                })}
            </ul>
        </section>
    );
};

export default ResourcesLogoBoxes;
