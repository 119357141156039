import React, { useState, useEffect, useCallback } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import FilterGroup from "../misc/filters/filter-group";

const CustomerFilter = ({ heading }) => {
    // Filter state.
    const [searchFilter, setSearchFilter] = useState("");
    const [sizeFilter, setSizeFilter] = useState("All");
    const [industryFilter, setIndustryFilter] = useState("All");
    const [studies, setStudies] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [sizes, setSizes] = useState([]);

    // Flags to determine whether we should filter.
    const shouldFilterBySearch = searchFilter?.length > 2;
    const shouldFilterBySizes = sizeFilter !== "All";
    const shouldFilterByIndustry = industryFilter !== "All";

    const { casestudies, industry, companySize } = useStaticQuery(graphql`
        query Query {
            casestudies: allContentfulCaseStudy(
                filter: { hidden: { eq: false } }
                sort: { fields: customer___company_name, order: ASC }
            ) {
                nodes {
                    id
                    slug
                    page_heading
                    customer {
                        company_name
                        one_color_logo {
                            file {
                                url
                            }
                        }
                    }
                    caseStudyIndustry {
                        industryName
                    }
                    companySize {
                        companySize
                    }
                    customerIntegrations {
                        customerIntegrations
                    }
                }
            }
            industry: allContentfulCustomerIndustry(sort: { fields: industryName, order: ASC }) {
                nodes {
                    industryName
                }
            }
            companySize: allContentfulCustomerCompanySize(sort: { fields: order, order: ASC }) {
                nodes {
                    companySize
                }
            }
        }
    `);

    const allCaseStudies = casestudies.nodes;
    const allIndustries = industry.nodes;
    const allSizes = companySize.nodes;

    useEffect(() => {
        const industryNames = allIndustries.map((industry) => industry.industryName);
        const uniqueSizes = allSizes.map((size) => size.companySize);
        setStudies(allCaseStudies);
        setIndustries(industryNames);
        setSizes(uniqueSizes);
    }, [allCaseStudies, allIndustries, allSizes]);

    const handleFiltering = useCallback(
        (data) => {
            let filteredItems = data;

            if (shouldFilterByIndustry) {
                filteredItems = filteredItems.filter(
                    (study) => industryFilter === study.caseStudyIndustry.industryName
                );
            }

            if (shouldFilterBySizes) {
                filteredItems = filteredItems.filter((study) => sizeFilter === study.companySize.companySize);
            }

            if (shouldFilterBySearch) {
                filteredItems = filteredItems.filter((study) => {
                    if (study.customerIntegrations !== null) {
                        return (
                            study.customer.company_name.toLowerCase().includes(searchFilter.toLowerCase()) ||
                            study.customerIntegrations.customerIntegrations
                                .toLowerCase()
                                .includes(searchFilter.toLowerCase())
                        );
                    } else {
                        return study.customer.company_name.toLowerCase().includes(searchFilter.toLowerCase());
                    }
                });
            }

            setStudies(filteredItems);
        },
        [industryFilter, searchFilter, sizeFilter, shouldFilterByIndustry, shouldFilterBySearch, shouldFilterBySizes]
    );

    useEffect(() => {
        handleFiltering(allCaseStudies);
    }, [allCaseStudies, handleFiltering]);

    const resetFilters = (e) => {
        e.preventDefault();
        setStudies(allCaseStudies);
        setIndustryFilter("All");
        setSizeFilter("All");
        setSearchFilter("");
    };

    const filters = [
        {
            title: null,
            currentValue: industryFilter,
            setter: setIndustryFilter,
            name: "customerIndustry",
            filterType: "Select",
            defaultValue: "All",
            defaultLabel: "All industries",
            values: industries,
        },
        {
            title: null,
            currentValue: sizeFilter,
            setter: setSizeFilter,
            name: "customerCompanySize",
            filterType: "Select",
            defaultValue: "All",
            defaultLabel: "All company sizes",
            values: sizes,
        },
        {
            title: null,
            currentValue: searchFilter,
            setter: setSearchFilter,
            name: "customerSearch",
            filterType: "Search",
            placeholder: "Integration, company or tool",
        },
    ];

    return (
        <section className="st-section">
            <div className="st-feature content">
                <h2 className="st-heading--2">{heading}</h2>
                <form className="filter-form">
                    <p className="left">Filter by:</p>
                    <FilterGroup filters={filters} />
                    <p className="right">
                        <button className="reset-filters st-button-plain--alt" onClick={resetFilters}>
                            Reset
                        </button>
                    </p>
                </form>
                <ul className="st-logo-boxes">
                    {studies.map((study) => (
                        <li className="logo-box" key={study.id}>
                            <Link to={`/customers/${study.slug}`} className="logo-box-border">
                                <div className="spacer">
                                    <img
                                        src={study.customer.one_color_logo.file.url}
                                        alt={study.customer.company_name}
                                    />
                                </div>
                                <div className="description">{study.page_heading}</div>
                                <p className="resource-link">See the story &rarr;</p>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default CustomerFilter;
