import Link from "../../../../src/components/misc/link";
import CTASection from "../../../../src/components/misc/cta-section";
import SectionSeparator from "../../../../src/components/layout/section-separator";
import MoreFeatures from "../../../../src/components/platform/more-features";
import assetPerformance from "../../../../src/assets/images/illustrations/asset-performance.svg";
import IconFeaturesSupport from "../../../../src/assets/images/icons/icon-features-support.svg";
import IconUserProfile from "../../../../src/assets/images/icons/icon-user-profile.svg";
import IconIntegrationsInfrastructure from "../../../../src/assets/images/icons/icon-integrations-infrastructure.svg";
import * as React from 'react';
export default {
  Link,
  CTASection,
  SectionSeparator,
  MoreFeatures,
  assetPerformance,
  IconFeaturesSupport,
  IconUserProfile,
  IconIntegrationsInfrastructure,
  React
};