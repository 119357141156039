import Link from "../../../../src/components/misc/link";
import CTASection from "../../../../src/components/misc/cta-section";
import SectionSeparator from "../../../../src/components/layout/section-separator";
import MoreFeatures from "../../../../src/components/platform/more-features";
import PlatformFeatures from "../../../../src/components/platform/platform-features";
import * as React from 'react';
export default {
  Link,
  CTASection,
  SectionSeparator,
  MoreFeatures,
  PlatformFeatures,
  React
};