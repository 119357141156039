import Link from "../../../../src/components/misc/link";
import MainNavigation from "../../../../src/components/navigation/main-navigation";
import ImgAloomaRedshift from "../../../../src/assets/images/amazon-redshift.svg";
import ImgSimpleSetup from "../../../../src/assets/images/icons/alooma-redshift/features-simple-setup.svg";
import ImgTrustedData from "../../../../src/assets/images/icons/alooma-redshift/features-trusted-data.svg";
import ImgReplication from "../../../../src/assets/images/icons/alooma-redshift/features-replication.svg";
import SectionSeparator from "../../../../src/components/layout/section-separator";
import IntegrationsFilter from "../../../../src/components/integrations/integrations-filter";
import * as React from 'react';
export default {
  Link,
  MainNavigation,
  ImgAloomaRedshift,
  ImgSimpleSetup,
  ImgTrustedData,
  ImgReplication,
  SectionSeparator,
  IntegrationsFilter,
  React
};