import SectionSeparator from "../../../../src/components/layout/section-separator";
import LeftRightSection from "../../../../src/components/layout/left-right-section";
import CTASection from "../../../../src/components/misc/cta-section";
import CTAButton from "../../../../src/components/misc/cta-button";
import SalesImage1 from "../../../../src/assets/images/solutions/01_sales_img@2x.jpg";
import SalesImage2 from "../../../../src/assets/images/solutions/01_sales_graphic.svg";
import SalesImage3 from "../../../../src/assets/images/solutions/02_sales_graphic.svg";
import * as React from 'react';
export default {
  SectionSeparator,
  LeftRightSection,
  CTASection,
  CTAButton,
  SalesImage1,
  SalesImage2,
  SalesImage3,
  React
};