import React from "react";
import DualCTA from "../misc/dual-cta";

const MiniCTA = ({ heading }) => {
    return (
        <div className="mini-cta compound">
            <div className="trial-cta">
                <p className="st-subheading--alt">{heading}</p>
                <ul>
                    <li>Unlimited data volume during trial</li>
                    <li>Set up in minutes</li>
                </ul>
            </div>
            <div className="trial-button">
                <DualCTA />
                <p>No credit card required</p>
            </div>
        </div>
    );
};

export default MiniCTA;
