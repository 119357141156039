import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "../misc/link";

const AnalysisToolsGrid = () => {
    const { tools } = useStaticQuery(graphql`
        query AnalysisToolsQuery {
            tools: allContentfulAnalysisTool(sort: { fields: name, order: ASC }) {
                nodes {
                    id
                    name
                    pageUrl
                    description {
                        description
                    }
                    logo {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);

    const analysisTools = tools.nodes;

    return (
        <div id="tools" className="st-section">
            <div className="st-slider">
                {analysisTools.map((tool) => (
                    <div className="slide-item">
                        <div className="info-text">
                            <h3>
                                <Link className="st-nodecoration" to={`/analysis-tools/${tool.pageUrl}/`}>
                                    <img src={tool.logo.file.url} alt={tool.name} />
                                </Link>
                            </h3>
                            <p>
                                <Link className="st-nodecoration" to={`/analysis-tools/${tool.pageUrl}/`}>
                                    {tool.description.description}
                                </Link>
                            </p>
                        </div>
                        <Link className="st-button-cta" to={`/analysis-tools/${tool.pageUrl}/`}>
                            Learn more<span className="arrow"></span>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AnalysisToolsGrid;
