import React from "react";

const MoreFeatures = ({ page_slug }) => {
    if (!page_slug) return null;

    return (
        <section className="st-section--border features">
            <div className="st-feature content">
                <h2 className="st-heading--2">More platform features</h2>
                <ul className="st-logo-boxes">
                    {page_slug !== "extensibility" && (
                        <li className="logo-box integration-box">
                            <a className="logo-box-border" href="/platform/extensibility">
                                <div className="logo-info">
                                    <p className="title">Extensibility</p>
                                    <p className="st-button-plain--alt">
                                        <span>See all features</span>
                                        <span className="arrow"></span>
                                    </p>
                                </div>
                            </a>
                        </li>
                    )}
                    {page_slug !== "orchestration" && (
                        <li className="logo-box integration-box">
                            <a className="logo-box-border" href="/platform/orchestration">
                                <div className="logo-info">
                                    <p className="title">Orchestration</p>
                                    <p className="st-button-plain--alt">
                                        <span>See all features</span>
                                        <span className="arrow"></span>
                                    </p>
                                </div>
                            </a>
                        </li>
                    )}
                    {page_slug !== "security" && (
                        <li className="logo-box integration-box">
                            <a className="logo-box-border" href="/platform/security">
                                <div className="logo-info">
                                    <p className="title">Security & Compliance</p>
                                    <p className="st-button-plain--alt">
                                        <span>See all features</span>
                                        <span className="arrow"></span>
                                    </p>
                                </div>
                            </a>
                        </li>
                    )}
                    {page_slug === "enterprise" && <span className="st-line-break"></span>}
                    {page_slug !== "performance" && (
                        <li className="logo-box integration-box">
                            <a className="logo-box-border" href="/platform/performance">
                                <div className="logo-info">
                                    <p className="title">Performance & Reliability</p>
                                    <p className="st-button-plain--alt">
                                        <span>See all features</span>
                                        <span className="arrow"></span>
                                    </p>
                                </div>
                            </a>
                        </li>
                    )}
                    {page_slug !== "embedding" && (
                        <li className="logo-box integration-box">
                            <a className="logo-box-border" href="/platform/embedding">
                                <div className="logo-info">
                                    <p className="title">Embedding</p>
                                    <p className="st-button-plain--alt">
                                        <span>See all features</span>
                                        <span className="arrow"></span>
                                    </p>
                                </div>
                            </a>
                        </li>
                    )}
                    {page_slug !== "datatransformation" && (
                        <li className="logo-box integration-box">
                            <a className="logo-box-border" href="/platform/datatransformation">
                                <div className="logo-info">
                                    <p className="title">Transformation & Quality</p>
                                    <p className="st-button-plain--alt">
                                        <span>See all features</span>
                                        <span className="arrow"></span>
                                    </p>
                                </div>
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        </section>
    );
};

export default MoreFeatures;
