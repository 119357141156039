export const extensibilityCodeSnippet = `# Example Request
curl -X POST https://api.stitchdata.com/
v2/import/validate \

    -H 'Content-Type: application/json' \

    -H 'Authorization: Bearer 
    < access-token >' \
    
    -d @filename

[
   {
      "client_id": 4231,
      "table_name": "users",
      "sequence": 100,
      "action": "upsert"
   }
]`;

export const connectAPIPostSnippet = `curl -X POST https://api.stitchdata.com/v3/accounts
-H "Authorization: Bearer <ACCESS_TOKEN>" 
-H "Content-Type: application/json"
-d "{
     "email": "stitch-api-test@stitchdata.com",
     "last_name": "Product Team",
     "partner_id": "<PARTNER_ID>",
     "first_name": "Stitch",
     "partner_secret": "<PARTNER_SECRET>",
     "company": "Stitch Product Team"
   }"`;

export const stitchOAuthJSSnippet = `Stitch.authorizeSource({
    "id": 45612,
    "ephemeral_token": "<EPHEMERAL_TOKEN>"
}).then((result) => {
    console.log(\`Integration created, type=\${result.type}, 
    id=\${result.id}\`);
}).catch((error) => {
    console.log("Integration not created.", error);
});`;

export const API1B_Snippet = `HTTP/1.1 200 OK
Content-Type: application/json;charset=ISO-8859-1

{
  "access_token":"<ACCESS_TOKEN>"
}`;

export const API2A_Snippet = `curl -X POST https://api.stitchdata.com/v4/sources
-H "Authorization: Bearer <ACCESS_TOKEN>" 
-H "Content-Type: application/json"
-d "{
        "type":"platform.hubspot",
        "display_name":"HubSpot",
        "properties":{
           "start_date":"2017-01-01T00:00:00Z",
           "frequency_in_minutes":"30"
        }
     }"`;

export const API2B_Snippet = `HTTP/1.1 200 OK
Content-Type: application/json;charset=ISO-8859-1

{
   "properties":{
      "frequency_in_minutes":"30",
      "image_version":"1.latest",
      "start_date":"2017-01-01T00:00:00Z"
   },
   "updated_at":"2018-02-06T16:25:06Z",
   "check_job_name":null,
   "name":"hubspot",
   "type":"platform.hubspot",
   "deleted_at":null,
   "system_paused_at":null,
   "stitch_client_id":<ACCOUNT_ID>,
   "paused_at":null,
   "id":<SOURCE_ID>,
   "display_name":"HubSpot",
   "created_at":"2018-02-06T16:25:06Z"
  }`;

export const API3A_Snippet = `curl -X PUT https://api.stitchdata.com/v3/destinations/{id}
-H "Authorization: Bearer <ACCESS_TOKEN>" 
-H "Content-Type: application/json"
-d "{
     "type":"postgres",
     "connection": {
       "host": "<HOST>",
       "port": 5432,
       "username": "<USERNAME>",
       "database": "<DATABASE>",
       "password": "<PASSWORD>",
       "ssl": false
       }
   }"`;

export const API3B_Snippet = `HTTP/1.1 200 OK
Content-Type: application/json;charset=ISO-8859-1

{
  "id":"<DESTINATION_ID>",
  "type":"postgres",
  "created_at":"2018-02-06T15:36:36Z",
  "updated_at":"2018-02-06T18:55:43Z",
  "connection": {
      "host":"<HOST>",
      "port":5432,
      "username":"<USERNAME>",
      "database":"<DATABASE>",
      "password":"<PASSWORD>",
      "ssl":false
  },
  "last_check":{
      "error": false,
      "started_at":"2018-02-06T16:15:19Z",
      "completed_at":"2018-02-06T16:16:21Z"
  }
}`;

export const JS1A_Snippet = `Stitch.authorizeSource({
    "id": 45612,
    "ephemeral_token": "<EPHEMERAL_TOKEN>"
}).then((result) => {
    console.log(\`Integration created, type=\${result.type}, 
    id=\${result.id}\`);
}).catch((error) => {
    console.log("Integration not created.", error);
});`;

export const JS2A_Snippet = `Stitch.selectStreamsForSource({
    "id": 123,
    "default_streams": {
        "campaigns": true,
        "companies": true
    },
    "ephemeral_token": "<EPHEMERAL_TOKEN>"
}).then((result) => {
    console.log(\`Integration created, type=\${result.type}, 
    id=\${result.id}\`);
}).catch((error) => {
    console.log("Integration not created.", error);
});`;

export const JS3A_Snippet = `Stitch.displayDiscoveryOutputForSource({
    "id": 123,
    "discovery_job_name": "987-123-4567891234-checks"
}).then((result) => {
    console.log(\`Integration created, type=\${result.type}, 
    id=\${result.id}\`);
}).catch((error) => {
    console.log("Integration not created.", error);
});`;
