import React from "react";
import Link from "./link";
import TwitterIcon from "../../assets/images/icons/social-icon-twitter.svg";
import BlogIcon from "../../assets/images/icons/social-icon-medium.svg";
import FacebookIcon from "../../assets/images/icons/social-icon-facebook.svg";

const MapAddressOverlay = () => {
    return (
        <div className="callout-detail-list" id="contacts">
            <dl>
                <dt className="st-heading--4">Stitch headquarters</dt>
                <dd>
                    1339 Chestnut Street
                    <br />
                    Suite 1500
                    <br />
                    Philadelphia, PA 19107
                </dd>
                <dt className="st-heading--4">Phone number</dt>
                <dd>
                    <Link to="tel://1-833-443-7545">833-443-7545</Link>
                </dd>
                <Link to="/contact">Contact Sales &gt;</Link>
                <dd>
                    <Link to="https://twitter.com/stitch_data">
                        <img src={TwitterIcon} alt="Icon" />
                    </Link>
                    <Link to="https://blog.stitchdata.com">
                        <img src={BlogIcon} alt="Icon" />
                    </Link>
                    <Link to="https://www.facebook.com/stitchdata/">
                        <img src={FacebookIcon} alt="Icon" />
                    </Link>
                </dd>
            </dl>
        </div>
    );
};

export default MapAddressOverlay;
