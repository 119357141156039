import React from "react";
import SSImg from "../../assets/images/section-separator.svg";

const SectionSeparator = () => {
    return (
        <section className="st-section">
            <div className="st-stitch-divider">
                <img src={SSImg} alt="Divider" />
            </div>
        </section>
    );
};

export default SectionSeparator;
