import SectionSeparator from "../../../../src/components/layout/section-separator";
import LeftRightSection from "../../../../src/components/layout/left-right-section";
import CTASection from "../../../../src/components/misc/cta-section";
import CTAButton from "../../../../src/components/misc/cta-button";
import MarketingImage1 from "../../../../src/assets/images/solutions/01_marketing_graphic.svg";
import MarketingImage2 from "../../../../src/assets/images/solutions/02_marketing_graphic.svg";
import MarketingImage3 from "../../../../src/assets/images/solutions/03_marketing_img@2x.jpg";
import * as React from 'react';
export default {
  SectionSeparator,
  LeftRightSection,
  CTASection,
  CTAButton,
  MarketingImage1,
  MarketingImage2,
  MarketingImage3,
  React
};