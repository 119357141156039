import Link from "../../../../src/components/misc/link";
import CTASection from "../../../../src/components/misc/cta-section";
import CTAButton from "../../../../src/components/misc/cta-button";
import AssetValues from "../../../../src/assets/images/illustrations/asset-values.svg";
import * as React from 'react';
export default {
  Link,
  CTASection,
  CTAButton,
  AssetValues,
  React
};