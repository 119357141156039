import CustomerLogoGrid from "../../../../src/components/customer/customer-logo-grid";
import SectionSeparator from "../../../../src/components/layout/section-separator";
import CustomerFilter from "../../../../src/components/customer/customer-filter";
import CustomerQuoteSlider from "../../../../src/components/customer/customer-quote-slider";
import CTASection from "../../../../src/components/misc/cta-section";
import * as React from 'react';
export default {
  CustomerLogoGrid,
  SectionSeparator,
  CustomerFilter,
  CustomerQuoteSlider,
  CTASection,
  React
};