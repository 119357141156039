import React from "react";

const FilterDropdown = ({ filter }) => {
    const { currentValue, defaultValue, defaultLabel, values, setter, name } = filter;

    const handleInputChange = (e) => {
        if (e.target.value !== defaultValue) {
            setter(e.target.value);
        } else {
            setter(defaultValue);
        }
    };

    return (
        <div className={"select-wrapper"}>
            <select
                value={currentValue}
                id={name}
                className={`input-dropdown`}
                name={name}
                onChange={handleInputChange}
            >
                <option value={defaultValue}>{defaultLabel}</option>
                {values.map((val, index) => {
                    if (!val) return null;
                    return (
                        <option key={index} value={val}>
                            {val}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

export default FilterDropdown;
