import CompetitorGrid from "../../../../src/components/competitor/competitor-grid";
import SectionSeparator from "../../../../src/components/layout/section-separator";
import CustomerQuoteSlider from "../../../../src/components/customer/customer-quote-slider";
import MiniCTA from "../../../../src/components/forms/mini-cta";
import CTASection from "../../../../src/components/misc/cta-section";
import * as React from 'react';
export default {
  CompetitorGrid,
  SectionSeparator,
  CustomerQuoteSlider,
  MiniCTA,
  CTASection,
  React
};