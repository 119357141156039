import UTMPurser from "./purser/index";
import PageViews from "./purser/PageViews";

// Returns the value of the cookie with the given name
const getTrackingCookieValue = (name) => {
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) return match[2];
};

// These imports are local imports. It's NOT a npm package. They live in
// the gatsby-plugin-marketo folder. It's based on Purser, which can be found here:
// https://github.com/bilbof/purser

export const onInitialClientRender = () => {
    // Collect UTM and/or create PURSER Local storage object,
    // or add to it if it exists. If it has been longer than
    // 30 minutes. It will count as a new "visit". Only fires when
    // the end user first gets to the site.
    UTMPurser.init();
};

export const onRouteUpdate = () => {
    // Collect Pageviews on route updates
    PageViews.add();

    // Get the current query string
    const queryString = window.location.search;

    // Get URL params
    const urlParams = new URLSearchParams(queryString);

    // If the param ty is present, scroll to the form
    if (urlParams.get("ty")) {
        const form = document.querySelector(".formHolder");
        if (form) {
            form.scrollIntoView();
        }
    }

    // Find the _mkto_trk param
    const mktoCookie = urlParams.get("_mkto_trk");

    // Set a cookie if the _mkto_trk param exists
    if (mktoCookie) {
        document.cookie = `_mkto_trk=${mktoCookie};path=/`;
    }

    // Array of associated domains, derived from an environment variable
    const associatedDomains = process.env.GATSBY_MARKETO_CROSS_TRACKING_DOMAINS
        ? process.env.GATSBY_MARKETO_CROSS_TRACKING_DOMAINS.split(" ")
        : false;

    // Get the Marketo Tracking params ("_mkto_trk") value
    const cookieValue = getTrackingCookieValue("_mkto_trk");

    if (cookieValue && associatedDomains) {
        // Loop through the associated domains and add the tracking string to the end of the URL
        associatedDomains.forEach((domain) => {
            // Get any links on the page that match the associated domain
            const assocLinks = document.querySelectorAll(`a[href*='${domain}']`);

            // For each link, add the tracking string to the "search parameters" of the URL
            [...assocLinks].map((link) => {
                const url = new URL(link.href);
                url.searchParams.append("_mkto_trk", cookieValue);
                return (link.href = url.href);
            });
        });
    }
};
