import React, { useState, useEffect, useCallback } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "../misc/link";
import FilterGroup from "../misc/filters/filter-group";

const IntegrationsFilter = ({ currentIntegrationName, destinationSlug = null }) => {
    const { integrations, analysisTools, destinations } = useStaticQuery(graphql`
        query IntegrationsQuery {
            integrations: allContentfulIntegration(
                filter: { hidden: { ne: true } }
                sort: { fields: name, order: ASC }
            ) {
                nodes {
                    __typename
                    contentful_id
                    name
                    url
                    logo {
                        file {
                            url
                        }
                    }
                    certified
                    enterprise
                    status
                }
            }
            analysisTools: allContentfulAnalysisTool(sort: { fields: name, order: ASC }) {
                nodes {
                    __typename
                    name
                }
            }
            destinations: allContentfulDataEndpoint(
                filter: { hidden: { ne: true } }
                sort: { fields: name, order: ASC }
            ) {
                nodes {
                    __typename
                    name
                }
            }
        }
    `);

    const allIntegrations = integrations.nodes;
    const allAnalysisTools = analysisTools.nodes;
    const allDestinations = destinations.nodes;

    const [searchResults, setSearchResults] = useState([]);
    const [searchFilter, setSearchFilter] = useState("");
    const [foundIntegrations, setIntegrations] = useState(allIntegrations);

    const currentIntegration = currentIntegrationName
        ? allIntegrations.filter((integration) => integration.name === currentIntegrationName)[0]
        : null;

    const handleFiltering = useCallback(
        (data) => {
            let filteredItems = data;

            filteredItems = filteredItems.filter((item) => {
                let name = item?.name;
                let enterprise = item?.enterprise ? "Enterprise" : "Standard";
                let certified = item?.certified ? "Stitch-certified" : "Community-supported";
                return `${name} ${enterprise} ${certified}`.toLowerCase().includes(searchFilter.toLowerCase());
            });

            const integrations = filteredItems.filter((item) => item.__typename === "ContentfulIntegration");

            setSearchResults(filteredItems);
            setIntegrations(integrations);
        },
        [searchFilter]
    );

    useEffect(() => {
        const allSearchableValues = [...allIntegrations, ...allAnalysisTools, ...allDestinations];
        handleFiltering(allSearchableValues);
    }, [searchFilter, handleFiltering, allIntegrations, allAnalysisTools, allDestinations]);

    const filters = [
        {
            title: null,
            currentValue: searchFilter,
            setter: setSearchFilter,
            name: "integrationSearch",
            filterType: "Search",
            placeholder: "Find a Data Source",
            type: "integration",
        },
    ];

    const generateDetailsButton = (integration) => {
        if (integration.status === "Coming Soon") {
            return (
                <p className="st-button-plain--alt">
                    <strong>Coming Soon!</strong>
                </p>
            );
        }
        return (
            <p className="st-button-plain--alt">
                <span>Details</span>
                <span className="arrow"></span>
            </p>
        );
    };

    const generatePillButtons = (integration) => {
        let pills = "";

        if (integration.enterprise === true) {
            pills = '<div class="logo-pill paid-status enterprise">Enterprise</div>';
        } else {
            pills = '<div class="logo-pill paid-status paid">Standard</div>';
        }

        if (integration.certified === true) {
            pills = pills.concat('<div class="logo-pill support-status">Stitch-certified</div>');
        } else {
            pills = pills.concat('<div class="logo-pill support-status">Community-supported</div>');
        }

        return <div className="logo-pills" dangerouslySetInnerHTML={{ __html: pills }} />;
    };

    const getUrl = (integration, currentIntegration, destinationSlug) => {
        if (currentIntegration) {
            return currentIntegration && currentIntegration.url && integration.url !== currentIntegration.url
                ? `${currentIntegration.url}/${integration.url}`
                : integration.url;
        }

        if (destinationSlug) {
            return `${integration.url}/${destinationSlug}`;
        }

        return integration.url;
    };

    const IntegrationResult = ({ integration, currentIntegration, destinationSlug }) => {
        const url = getUrl(integration, currentIntegration, destinationSlug);
        return (
            <li key={integration.contentful_id} className="logo-box integration-box">
                <Link className="logo-box-border" to={`/integrations/${url}/`}>
                    <div className="logo-info">
                        <img src={integration.logo.file.url} alt={integration.name} />
                        <p>{integration.name}</p>
                        {generateDetailsButton(integration)}
                    </div>
                    {generatePillButtons(integration)}
                </Link>
            </li>
        );
    };

    const AnalysisToolResult = () => {
        return (
            <li id="analysisToolBox" className="logo-box integration-box" data-integration-category-name="Request">
                <Link className="logo-box-border" to={`/integrations/analysis-tools`}>
                    <div className="logo-info">
                        <p id="analysisToolBoxName">{searchFilter}</p>
                        <p>Analysis Tool</p>
                        <p className="category">Stitch can consolidate your data for this tool</p>
                        <p className="switch-page">See all analysis tools</p>
                    </div>
                </Link>
            </li>
        );
    };

    const DestinationResult = () => {
        return (
            <li id="destinationBox" className="logo-box integration-box" data-integration-category-name="Request">
                <Link className="logo-box-border" to={`/integrations/destinations`}>
                    <div className="logo-info">
                        <p id="destinationBoxName">{searchFilter}</p>
                        <p>Data Destination</p>
                        <p className="category">Supported by Stitch</p>
                        <p></p>
                        <br />
                        <p className="switch-page">See all destinations</p>
                    </div>
                </Link>
            </li>
        );
    };

    const NoResults = () => (
        <li id="requestBox" className="logo-box integration-box" data-integration-category-name="Request">
            <Link className="logo-box-border" to={`/integrations/request`}>
                <div className="logo-info">
                    <p id="requestBoxName"></p>
                    <p>Not yet supported.</p>
                    <p className="category">Interested in having this source built for you?</p>
                    <p className="st-button-plain--alt">
                        Make a request
                        <span className="arrow"></span>
                    </p>
                    <div className="logo-pills">
                        <div className="logo-pill paid-status enterprise-only">Enterprise Only</div>
                    </div>
                </div>
            </Link>
        </li>
    );

    return (
        <section className="st-section" id="sources">
            <ul className="st-logo-boxes integrations-list" id="sourcesList">
                <FilterGroup filters={filters} />
                {foundIntegrations.map((integration, i) => (
                    <IntegrationResult
                        key={integration.contentful_id}
                        integration={integration}
                        currentIntegration={currentIntegration}
                        destinationSlug={destinationSlug}
                    />
                ))}
                {foundIntegrations.length === 0 &&
                    searchResults.length > 0 &&
                    searchResults[0].__typename === "ContentfulAnalysisTool" && <AnalysisToolResult />}
                {foundIntegrations.length === 0 &&
                    searchResults.length > 0 &&
                    searchResults[0].__typename === "ContentfulDataEndpoint" && <DestinationResult />}
                {foundIntegrations.length === 0 && searchResults.length === 0 && <NoResults />}
            </ul>
        </section>
    );
};

export default IntegrationsFilter;
