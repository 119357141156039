import React from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../../assets/images/icons/icon_map_marker.png";

const MapMarker = ({ text }) => <img src={Marker} alt={text} title={text} width={32} height={50} />;

const GoogleMap = () => {
    const apiKey = process.env.GATSBY_GOOGLE_MAPS_API_KEY;

    const defaultProps = {
        center: {
            lat: 39.9509,
            lng: -75.162,
        },
        zoom: 16,
    };

    const createMapOptions = () => {
        return {
            gestureHandling: "none",
            zoomControl: false,
            disableDefaultUI: true,
            draggableCursor: "default",
            styles: [
                { featureType: "administrative", elementType: "all", stylers: [{ saturation: "-100" }] },
                { featureType: "administrative.province", elementType: "all", stylers: [{ visibility: "off" }] },
                {
                    featureType: "landscape",
                    elementType: "all",
                    stylers: [{ saturation: -100 }, { lightness: 65 }, { visibility: "on" }],
                },
                { featureType: "poi", elementType: "all", stylers: [{ visibility: "off" }] },
                { featureType: "road", elementType: "all", stylers: [{ saturation: "-100" }] },
                { featureType: "road.highway", elementType: "all", stylers: [{ visibility: "simplified" }] },
                { featureType: "road.arterial", elementType: "all", stylers: [{ lightness: "30" }] },
                { featureType: "road.local", elementType: "all", stylers: [{ lightness: "40" }] },
                {
                    featureType: "transit",
                    elementType: "all",
                    stylers: [{ saturation: -100 }, { visibility: "simplified" }],
                },
                {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [{ hue: "#ffff00" }, { lightness: -25 }, { saturation: -97 }],
                },
                { featureType: "water", elementType: "labels", stylers: [{ lightness: -25 }, { saturation: -100 }] },
            ],
        };
    };

    return (
        <div id="map" style={{ height: "400px", width: "100%" }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: apiKey }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                options={createMapOptions}
            >
                <MapMarker lat={39.951} lng={-75.16349} text="Stitch HQ" />
            </GoogleMapReact>
        </div>
    );
};

export default GoogleMap;
