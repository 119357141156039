import Link from "../../../../src/components/misc/link";
import CTASection from "../../../../src/components/misc/cta-section";
import AssetGlobe from "../../../../src/assets/images/icons/icon-globe.svg";
import AssetScalability from "../../../../src/assets/images/icons/icon-features-scalability.svg";
import AssetCompass from "../../../../src/assets/images/icons/icon-compass.svg";
import AssetProfile from "../../../../src/assets/images/icons/icon-user-profile.svg";
import AssetIndustry from "../../../../src/assets/images/icons/icon-industry.svg";
import AssetIntegrations from "../../../../src/assets/images/icons/icon-integrations-simple.svg";
import * as React from 'react';
export default {
  Link,
  CTASection,
  AssetGlobe,
  AssetScalability,
  AssetCompass,
  AssetProfile,
  AssetIndustry,
  AssetIntegrations,
  React
};