import Link from "../../../../src/components/misc/link";
import CTASelector from "../../../../src/components/micro-sites/cta-selector";
import image_etl_traditional from "../../../../src/assets/images/illustrations/asset-etl-traditional.png";
import image_etl_modern from "../../../../src/assets/images/illustrations/asset-etl-modern.png";
import * as React from 'react';
export default {
  Link,
  CTASelector,
  image_etl_traditional,
  image_etl_modern,
  React
};