import React, { useState, useEffect } from "react";
import Link from "./link";

const PricingCalculator = () => {
    const [rows, setRows] = useState(0);
    const [term, setTerm] = useState("mo");
    const [price, setPrice] = useState(100);

    const handleRowChange = (e) => {
        setRows(e.target.value);
    };

    const handleTermChange = (e) => {
        if (e.target.checked) {
            setTerm("yr");
        } else {
            setTerm("mo");
        }
    };

    useEffect(() => {
        const monthlyPrices = [
            { rows: "5", price: "100", pretty: "100" },
            { rows: "10", price: "180", pretty: "180" },
            { rows: "25", price: "350", pretty: "350" },
            { rows: "50", price: "500", pretty: "500" },
            { rows: "100", price: "750", pretty: "750" },
            { rows: "200", price: "1000", pretty: "1,000" },
            { rows: "300", price: "1250", pretty: "1,250" },
        ];
        const annualPrices = [
            { rows: "5", price: "1000", pretty: "1,000" },
            { rows: "10", price: "1800", pretty: "1,800" },
            { rows: "25", price: "3500", pretty: "3,500" },
            { rows: "50", price: "5000", pretty: "5,000" },
            { rows: "100", price: "7500", pretty: "7,500" },
            { rows: "200", price: "10000", pretty: "10,000" },
            { rows: "300", price: "12500", pretty: "12,500" },
        ];
        if (term === "mo") {
            setPrice(monthlyPrices[rows].pretty);
        } else {
            setPrice(annualPrices[rows].pretty);
        }
    }, [rows, term]);

    return (
        <section className="st-section" id="pricing">
            <div className="pricing-table">
                <div className="plan">
                    <div class="value-note">Get 2 months free on annual</div>
                    <div className="content-container">
                        <div className="pricingHeading">
                            <h3 className="st-heading--3">Standard</h3>
                            <p>Start with the basics. Best for individuals just getting started with data ingestion.</p>
                        </div>
                        <h4 className="price">
                            <span className="priceFrequencyDescriptor">Starts at</span>
                            {/* <span className="superscript">USD</span> */}
                            <span id="standardPrice">
                                ${price}/{term}
                            </span>
                            {/* <span className="subscript time">/{term}</span> */}
                        </h4>
                        <div className="content-header">
                            <strong>Choose rows</strong>/month (in millions):
                            <input
                                type="range"
                                min="0"
                                max="6"
                                step="1"
                                value={rows}
                                onChange={handleRowChange}
                                style={{ width: "calc(100% - 14px)", marginLeft: "7px" }}
                            />
                            <div className="stops">
                                <div>5</div>
                                <div>10</div>
                                <div>25</div>
                                <div>50</div>
                                <div>100</div>
                                <div>200</div>
                                <div>300</div>
                            </div>
                        </div>
                        <div className="payment-switch-heading">Billing cycle:</div>
                        <form className="payment-switch-container">
                            <span className={`payment-label ${term === "mo" ? "active" : ""}`}>Monthly</span>
                            <label className="payment-switch">
                                <input className="payment-checkbox" type="checkbox" onChange={handleTermChange} />
                                <span className="payment-slider round"></span>
                            </label>
                            <span className={`payment-label ${term === "yr" ? "active" : ""}`}>Annual</span>
                        </form>
                    </div>
                    <p>
                        <Link
                            to={"https://app.stitchdata.com/signup"}
                            id="signup-button-signup-paid"
                            className="st-button-cta-pri"
                        >
                            Start free trial <span className="arrow"></span>
                        </Link>
                    </p>

                    <div className="mobile-content">
                        <ul>
                            <li>14-day unlimited trial</li>
                            <li>
                                <Link to={"/integrations/sources"}>Standard</Link> integrations
                            </li>
                            <li>SOC 2 Compliance</li>
                            <li>Free historical data loads</li>
                            <li>5 Stitch users</li>
                            <li>10 sources</li>
                            <li>7 days of log retention</li>
                            <li>Chat support</li>
                        </ul>
                    </div>
                </div>

                <div className="plan">
                    <div className="content-container">
                        <div className="pricingHeading">
                            <h3 className="st-heading--3">Advanced</h3>
                            <p>
                                Advanced features for teams who want even more control and extensibility of their data
                                pipelines.
                            </p>
                        </div>
                        <h4 className="price">
                            <span id="advancedPrice">
                                $1250/mo
                                <sup>
                                    <Link className="footnote" to="#monthly_fn">
                                        *
                                    </Link>
                                </sup>
                            </span>
                        </h4>
                    </div>
                    <p>
                        <Link
                            to={"/contact-advanced"}
                            id="contact-button-signup-enterprise"
                            className="st-button-cta-pri"
                        >
                            Contact sales <span className="arrow"></span>
                        </Link>
                    </p>

                    <div className="mobile-content">
                        <ul>
                            <li>Free historical data loads</li>
                            <li>3 Destinations</li>
                            <li>SOC 2 Compliance</li>
                            <li>
                                <Link to={"https://www.stitchdata.com/docs/developers/webhooks/post-load-webhooks"}>
                                    Post-load webhooks
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={
                                        "https://www.stitchdata.com/docs/replication/replication-scheduling/advanced-scheduling"
                                    }
                                >
                                    Advanced scheduling
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={
                                        "https://www.stitchdata.com/docs/account-security/notifications/extend-email-notifications"
                                    }
                                >
                                    Notification extensibility
                                </Link>
                            </li>
                            <li>API Key Management</li>
                            <li>SSO</li>
                            <li>60 Days of log retention</li>
                            <li>Gold Support</li>
                            <li>Global Customer Support Manager</li>
                        </ul>
                    </div>
                </div>

                <div className="plan">
                    <div className="content-container">
                        <div className="pricingHeading">
                            <h3 className="st-heading--3">Premium</h3>
                            <p>
                                For fast growing organizations with the highest data volumes and the need for
                                best-in-class security and compliance.
                            </p>
                        </div>
                        <h4 className="price">
                            <span id="premiumPrice">
                                $2500/mo
                                <sup>
                                    <Link className="footnote" to="#monthly_fn">
                                        *
                                    </Link>
                                </sup>
                            </span>
                        </h4>
                    </div>
                    <p>
                        <Link
                            to={"/contact-premium"}
                            id="contact-button-signup-enterprise"
                            className="st-button-cta-pri"
                        >
                            Contact sales <span className="arrow"></span>
                        </Link>
                    </p>

                    <div className="mobile-content">
                        <ul>
                            <li>Free historical data loads</li>
                            <li>5 Destinations</li>
                            <li>SOC2 & HIPAA Compliance</li>
                            <li>
                                <Link to={"https://www.stitchdata.com/docs/developers/webhooks/post-load-webhooks"}>
                                    Post-load webhooks
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={
                                        "https://www.stitchdata.com/docs/replication/replication-scheduling/advanced-scheduling"
                                    }
                                >
                                    Advanced scheduling
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={
                                        "https://www.stitchdata.com/docs/account-security/notifications/extend-email-notifications"
                                    }
                                >
                                    Notification extensibility
                                </Link>
                            </li>
                            <li>API Key Management</li>
                            <li>SSO</li>
                            <li>60 Days of log retention</li>
                            <li>Platinum Support</li>
                            <li>Global Customer Support Manager</li>
                            <li>Advanced Connectivity</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="pricing-footnote" id="monthly_fn">
                <sup>*</sup> Advanced and Premium plans are billed annually
            </div>
        </section>
    );
};

export default PricingCalculator;
