import React from "react";
import FilterDropdown from "./filter-dropdown";
import FilterSearch from "./filter-search";

// Map of filter type to FilterGroup component.
const filterTypeMap = {
    Select: FilterDropdown,
    Search: FilterSearch,
};

const FilterGroup = ({ filters, resetFunction = null }) => {
    return (
        <>
            {filters.map((filterGroup, index) => {
                if (!filterGroup) return null;
                const FilterType = filterTypeMap[filterGroup.filterType];
                return <FilterType key={index} filter={filterGroup} filterID={`filterID${index}`} />;
            })}
            {resetFunction && (
                <button type="button" className={`resetButton`} onClick={resetFunction}>
                    Reset
                </button>
            )}
        </>
    );
};

export default FilterGroup;
