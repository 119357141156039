import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const CustomerLogoGrid = () => {
    const { customers } = useStaticQuery(graphql`
        query MyQuery {
            customers: allContentfulCustomer(
                filter: { is_enterprise: { eq: true } }
                sort: { order: ASC, fields: id }
            ) {
                nodes {
                    id
                    company_name
                    one_color_logo {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);

    const unique = customers.nodes.filter((v, i, a) => a.findIndex((t) => t.company_name === v.company_name) === i);

    return (
        <section className="st-section all-customer-logos">
            {unique.map((cust) => (
                <img
                    key={cust.id}
                    src={cust.one_color_logo.file.url}
                    alt={`${cust.company_name}`}
                    className="customer-logo"
                />
            ))}
        </section>
    );
};

export default CustomerLogoGrid;
