import React from "react";
import PartnerBoxGrid from "./partners-box-grid";

const PartnerBoxSection = ({ heading, paragraph, category, id }) => {
    return (
        <>
            <section className="st-section warehouses" id={id}>
                <div className="st-heading-group st-heading-group--half">
                    <h2 className="st-heading--2">{heading}</h2>
                    <p className="st-subheading">{paragraph}</p>
                </div>
                <PartnerBoxGrid category={category} />
            </section>
        </>
    );
};

export default PartnerBoxSection;
