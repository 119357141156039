import React from "react";
import {
    formValidation,
    destyleMktoForm,
    formExperimentFill,
    zoomInfoIntegration,
    applyCheckboxClasses,
    addPurserValuesToMarketoForms,
    formRedirect,
    formVideoModal,
    normalFormSubmission,
} from "../../../plugins/gatsby-plugin-marketo/utils/form-functions";
import { useMarketoForm } from "../../hooks/useMarketoForm";
import PrivacyPolicyNotice from "./privacy-policy";

const MarketoForm = ({
    formHeader,
    formId,
    formCallback,
    formThankYouMessage,
    formConversionType,
    redirectionUrl = null,
    videoSrc = null,
}) => {
    const allValidationMessages = {
        thank_you_text: "Thank you for reaching out to us. We will be in touch!",
        firstname_error: "First Name must not include symbols or numbers.",
        lastname_error: "Last Name must not include symbols or numbers.",
        phone_error: "Phone number is invalid. Must be 8 to 20 digits.",
    };

    useMarketoForm({
        formId,
        callback: () =>
            callBacks(
                formCallback,
                allValidationMessages,
                formThankYouMessage,
                formConversionType,
                redirectionUrl,
                videoSrc
            ),
    });

    const header = formHeader ? <div className="formHeader">{formHeader}</div> : null;

    return (
        <div className={`formHolder form-id-${formId}`}>
            {header}
            <form id={`mktoForm_${formId}`} />
            <PrivacyPolicyNotice />
        </div>
    );
};

const callBacks = (callback, validationMessages, formThankYouMessage, formConversionType, redirectionUrl, videoSrc) => {
    const thankYou = formThankYouMessage ? formThankYouMessage : validationMessages.thank_you_text;

    const coversionMap = {
        none: "none",
        demo: "demo",
        trial: "trial",
        content: "content",
        webinar: "webinar",
        contact: "contact",
    };

    const conversionType = coversionMap[formConversionType] ?? coversionMap["none"];

    if (typeof window !== "undefined" && window.MktoForms2) {
        // eslint-disable-next-line no-undef
        MktoForms2.whenRendered((form) => {
            destyleMktoForm(form);
        });
        // eslint-disable-next-line no-undef
        MktoForms2.whenReady((form) => {
            // Adding custom styles to field wrapper elements when checkbox is present
            let formEl = form.getFormElem()[0];
            formEl.addEventListener("change", applyCheckboxClasses);
            applyCheckboxClasses();
            formExperimentFill();

            // Initialize the ZoomInfo integration
            zoomInfoIntegration.init(form);

            // Add Values from the Purser to the forms
            addPurserValuesToMarketoForms(form);

            // Initialize the form validation
            formValidation.init(validationMessages);

            // Various Form Callbacks goes here
            if (callback === "Redirect to Thank You Page" || callback === "Redirect") {
                formRedirect(form, redirectionUrl, conversionType);
            }

            if (callback === "Custom Thank You" || callback === "Normal") {
                normalFormSubmission(form, thankYou, conversionType);
            }

            if (callback === "Video Modal") {
                formVideoModal(form, videoSrc);
            }
        });
    }
};

export default MarketoForm;
