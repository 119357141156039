import React from "react";
import Link from "../../misc/link";

const CTAFour = ({ title, position }) => {
    const heading = title ?? `ETL in minutes >`;
    return (
        <div className="st-ctas-btn-container-a">
            <div className="st-ctas-btn-container-b">
                <Link
                    to={`https://get.stitchdata.com/stitch-signup?utm_source=ctaFour-${position}&utm_medium=cta&utm_campaign=internal&utm_content=${heading}`}
                    className="st-ctas-btn"
                >
                    {heading}
                </Link>
                <br />
                <br />
                Extract from the sources that run your business.
            </div>
        </div>
    );
};

export default CTAFour;
