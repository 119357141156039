import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const CustomerLogobar = () => {
    const { customers } = useStaticQuery(graphql`
        query CustomerQuery {
            customers: allContentfulCustomer(
                filter: { show_on_logo_bar: { eq: true } }
                sort: { order: ASC, fields: id }
            ) {
                nodes {
                    id
                    slug
                    logo {
                        title
                    }
                    one_color_logo {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `);

    const allCustomers = customers.nodes;

    return (
        <div className="st-section partners">
            {allCustomers.map((cust) => (
                <img
                    key={cust.id}
                    src={cust.one_color_logo.file.url}
                    alt={`#${cust.logo.title}`}
                    className="partner-logo"
                />
            ))}
        </div>
    );
};

export default CustomerLogobar;
