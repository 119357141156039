import Link from "../../../../src/components/misc/link";
import CTASection from "../../../../src/components/misc/cta-section";
import SectionSeparator from "../../../../src/components/layout/section-separator";
import MoreFeatures from "../../../../src/components/platform/more-features";
import featuresGovernance from "../../../../src/assets/images/illustrations/asset-features-governance.svg";
import * as React from 'react';
export default {
  Link,
  CTASection,
  SectionSeparator,
  MoreFeatures,
  featuresGovernance,
  React
};