import CTASection from "../../../../src/components/misc/cta-section";
import CTAButton from "../../../../src/components/misc/cta-button";
import FAQSection from "../../../../src/components/misc/faq-section";
import FAQQuestion from "../../../../src/components/misc/faq-question";
import Link from "../../../../src/components/misc/link";
import * as React from 'react';
export default {
  CTASection,
  CTAButton,
  FAQSection,
  FAQQuestion,
  Link,
  React
};