import React from "react";
import Link from "./link";

const DualCTA = ({ noContact = false, containerClasses = "st-dual-ctas flex-center", description = true }) => {
    return (
        <>
            <div className={containerClasses}>
                <Link to={"https://app.stitchdata.com/signup"} className="st-button--inline st-button-cta-pri">
                    Sign up for free →
                </Link>
                {!noContact && <Link to={"/contact"} className="st-button--inline st-button-cta-ter">
                    Contact Sales →
                </Link>}
            </div>
            {description && (
                <p className="little-description">
                    <span>Set up in minutes</span>
                    <span>Unlimited data volume during trial</span>
                </p>
            )}
        </>
    );
};

export default DualCTA;
