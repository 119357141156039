import SectionSeparator from "../../../../src/components/layout/section-separator";
import LeftRightSection from "../../../../src/components/layout/left-right-section";
import CTASection from "../../../../src/components/misc/cta-section";
import CTAButton from "../../../../src/components/misc/cta-button";
import ProductImage1 from "../../../../src/assets/images/solutions/01_product_graphic.svg";
import ProductImage2 from "../../../../src/assets/images/solutions/02_product_graphic.svg";
import ProductImage3 from "../../../../src/assets/images/solutions/03_product_graphic.svg";
import * as React from 'react';
export default {
  SectionSeparator,
  LeftRightSection,
  CTASection,
  CTAButton,
  ProductImage1,
  ProductImage2,
  ProductImage3,
  React
};