import PartnerBoxSection from "../../../../src/components/partners/partner-box-section";
import SectionSeparator from "../../../../src/components/layout/section-separator";
import CTASection from "../../../../src/components/misc/cta-section";
import CTAButton from "../../../../src/components/misc/cta-button";
import * as React from 'react';
export default {
  PartnerBoxSection,
  SectionSeparator,
  CTASection,
  CTAButton,
  React
};