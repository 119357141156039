import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import FilterDropdown from "./filters/filter-dropdown";
import Link from "../misc/link";

const SourceDestinationSelector = ({ heading, position }) => {
    const [searchString, setSearchString] = useState("");
    const [selectedIntegrationSource, setSelectedIntegrationSource] = useState("");
    const [selectedDestination, setSelectedDestination] = useState("");
    const { integrations, destinations } = useStaticQuery(graphql`
        query SelectorQuery {
            integrations: allContentfulIntegration(
                filter: { hidden: { ne: true } }
                sort: { fields: name, order: ASC }
            ) {
                nodes {
                    contentful_id
                    name
                    url
                }
            }
            destinations: allContentfulDataEndpoint(
                filter: { hidden: { ne: true } }
                sort: { fields: name, order: ASC }
            ) {
                nodes {
                    contentful_id
                    name
                    url
                }
            }
        }
    `);

    const allIntegrations = integrations.nodes;
    const allDestinations = destinations.nodes;
    const allDestinationNames = ["- I'm not sure yet -"].concat(allDestinations.map((item) => item.name));

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        setSearchString(string);

        const match = (s, arr) => {
            const re = new RegExp(s.split("").join(".*"), "i");

            return arr.filter((item) => {
                if (item.name.match(re)) {
                    return item;
                }

                return null;
            });
        };

        return match(string, results);
    };

    const handleOnSelect = (item) => {
        // the item selected
        setSelectedIntegrationSource(item.url);
    };

    const formatResult = (item) => {
        return item;
        // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
    };

    const getSelectedDestinationUrl = (selectedDestination) => {
        return allDestinations
            .filter((destination) => destination.name === selectedDestination)
            .map((item) => item.url);
    };

    const getSelectedSourceName = (selectedIntegrationSource) => {
        return allIntegrations
            .filter((integration) => integration.url === selectedIntegrationSource)
            .map((item) => item.name);
    };

    const disabledLink = selectedIntegrationSource && selectedDestination ? "" : "disabled-link";

    const selectedUrl =
        selectedIntegrationSource && selectedDestination
            ? `/integrations/${selectedIntegrationSource}/${getSelectedDestinationUrl(selectedDestination)}`
            : null;

    return (
        <div id="srcDestCta" className="cta-drop-wrap">
            <div className="cta-drop">
                <section className="picker-wrap">
                    <section className="st-section">
                        <h2 className="st-heading--2">{heading}</h2>
                        <p>
                            {" "}
                            Let's see if Stitch is a good fit to move your data from{" "}
                            <span id="showSource" className="inline-block">
                                {selectedIntegrationSource ? getSelectedSourceName(selectedIntegrationSource) : "A"}
                            </span>{" "}
                            to{" "}
                            <span id="showDestination" className="inline-block">
                                {selectedDestination && selectedDestination !== "- I'm not sure yet -"
                                    ? selectedDestination
                                    : "B"}
                            </span>
                            .
                        </p>
                        <div className="SourceToDestinationForm">
                            <div className="formLabel fromFormLabel">From</div>
                            <div className="source-picker">
                                <ReactSearchAutocomplete
                                    items={allIntegrations}
                                    onSearch={handleOnSearch}
                                    onSelect={handleOnSelect}
                                    inputSearchString={searchString}
                                    formatResult={formatResult}
                                    resultStringKeyName="name"
                                    showIcon={false}
                                    placeholder={`Source (start typing)`}
                                    fuseOptions={{
                                        threshold: 0,
                                        minMatchCharLength: 1,
                                    }}
                                    styling={{
                                        backgroundColor: "#FFF",
                                        border: "none",
                                        padding: "19px",
                                        fontSize: "16px",
                                        height: "61px",
                                        borderRadius: "none",
                                        hoverBackgroundColor: "#fff",
                                        boxShadow: "none",
                                        fontFamily: "'PT Sans', sans-serif",
                                        placeholderColor: "#000",
                                    }}
                                />
                            </div>
                            <div className="formLabel fromFormLabel">to</div>
                            <FilterDropdown
                                filter={{
                                    currentValue: selectedDestination,
                                    defaultValue: "",
                                    defaultLabel: "Select a Destination",
                                    values: allDestinationNames,
                                    setter: setSelectedDestination,
                                    name: "DestinationPicker",
                                }}
                            />
                            <Link
                                to={selectedUrl}
                                id={`sourceDestinationButton`}
                                className={`st-button picker-button ${disabledLink}`}
                            >
                                Go
                            </Link>
                        </div>
                    </section>
                </section>
            </div>
        </div>
    );
};

export default SourceDestinationSelector;
