export const headerMenu = [
    {
        heading: "Platform",
        items: [
            {
                href: "/platform/extensibility/",
                label: "Extensibility",
            },
            {
                href: "/platform/orchestration/",
                label: "Orchestration",
            },
            {
                href: "/platform/security/",
                label: "Security & Compliance",
            },
            {
                href: "/platform/performance/",
                label: "Performance & Reliability",
            },
            {
                href: "/platform/embedding/",
                label: "Embedding",
            },
            {
                href: "/platform/datatransformation/",
                label: "Transformation & Quality",
            },
            {
                href: "/platform/enterprise/",
                label: "For enterprise",
            },
        ],
    },
    {
        heading: "Solutions",
        items: [
            {
                href: "/solutions/sales/",
                label: "Sales",
            },
            {
                href: "/solutions/marketing/",
                label: "Marketing",
            },
            {
                href: "/solutions/product/",
                label: "Product Intelligence",
            },
        ],
    },
    {
        heading: "Integrations",
        items: [
            {
                href: "/integrations/sources/",
                label: "Sources",
            },
            {
                href: "/integrations/destinations/",
                label: "Destinations",
            },
            {
                href: "/integrations/analysis-tools/",
                label: "Analysis Tools",
            },
        ],
    },
    {
        href: "/pricing/",
        label: "Pricing",
    },
    {
        href: "/customers/",
        label: "Customers",
    },
    {
        href: "https://www.stitchdata.com/docs",
        label: "Documentation",
    },
    {
        href: "https://app.stitchdata.com",
        label: "Sign in",
        className: "st-button-plain--relay",
    },
    {
        href: "https://app.stitchdata.com/signup",
        label: "Sign up for free →",
        className: "st-button--inline st-button-cta-pri",
    },
    {
        href: "/contact",
        label: "Contact Sales →",
        className: "st-button--inline st-button-cta-ter",
    },
];

export const lpHeaderMenu = [
    {
        href: "/contact",
        label: "Contact",
        className: "st-button--inline st-button-outline",
    },
    {
        href: "/signup",
        label: "Free Trial",
        className: "st-button--inline st-button-inverse",
    },
];

export const footerMenu = [
    {
        heading: "Product",
        items: [
            {
                href: "/platform/extensibility/",
                label: "Extensibility",
            },
            {
                href: "/platform/orchestration/",
                label: "Orchestration",
            },
            {
                href: "/platform/security/",
                label: "Security",
            },
            {
                href: "/platform/performance/",
                label: "Performance",
            },
            {
                href: "/platform/embedding/",
                label: "Embedding",
            },
            {
                href: "/pricing/",
                label: "Pricing",
            },
            {
                href: "/platform/enterprise/",
                label: "For enterprise",
            },
            {
                href: "/vs/",
                label: "Compare ETL tools",
            },
        ],
    },
    {
        heading: "Solutions",
        items: [
            {
                href: "/integrations/sources/",
                label: "Sources",
            },
            {
                href: "/solutions/sales/",
                label: "Sales",
            },
            {
                href: "/solutions/marketing/",
                label: "Marketing",
            },
            {
                href: "/solutions/product/",
                label: "Product Intelligence",
            },
        ],
    },
    {
        heading: "Integrations",
        items: [
            {
                href: "/integrations/sources/",
                label: "Sources",
            },
            {
                href: "/integrations/destinations/",
                label: "Destinations",
            },
            {
                href: "/integrations/analysis-tools/",
                label: "Analysis Tools",
            },
            {
                href: "/integrations/talend/",
                label: "Talend Use Cases",
            },
        ],
    },
    {
        heading: "Learn",
        items: [
            {
                href: "https://www.stitchdata.com/docs",
                label: "Documentation",
            },
            {
                href: "https://community.stitchdata.com/",
                label: "Community",
            },
            {
                href: "https://www.stitchdata.com/blog/",
                label: "Blog",
            },
            {
                href: "https://www.stitchdata.com/docs/changelog/",
                label: "Changelog",
            },
            {
                href: "/resources/",
                label: "Resources",
            },
            {
                href: "https://github.com/stitchdata",
                label: "Github",
            },
            {
                href: "https://status.stitchdata.com/",
                label: "Status",
            },
        ],
    },
    {
        heading: "Company",
        items: [
            {
                href: "/company/",
                label: "Company",
            },
            {
                href: "/jobs/",
                label: "Careers",
            },
            {
                href: "/contact/",
                label: "Contact",
            },
            {
                href: "/customers/",
                label: "Customers",
            },
            {
                href: "/partners/",
                label: "Partners",
            },
        ],
    },
    {
        heading: "Social",
        items: [
            {
                href: "https://twitter.com/stitch_data/",
                label: "Twitter",
            },
            {
                href: "https://www.linkedin.com/company/stitch-data",
                label: "LinkedIn",
            },
            {
                href: "https://www.facebook.com/stitchdata",
                label: "Facebook",
            },
            {
                href: "https://www.instagram.com/stitch_data",
                label: "Instagram",
            },
        ],
    },
];
