import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "../misc/link";

const CompetitorGrid = () => {
    const { competitors } = useStaticQuery(graphql`
        query CompetitorsQuery {
            competitors: allContentfulCompetitor(sort: { fields: slug, order: ASC }) {
                nodes {
                    name
                    slug
                    competitorLogo {
                        file {
                            url
                        }
                        title
                    }
                }
            }
        }
    `);

    const allCompetitors = competitors.nodes;

    const CompetitorItem = ({ competitor, index }) => {
        return (
            <Link key={`competitor-${index}`} to={`/vs/${competitor.slug}/`} className={`company-wrap-link`}>
                <span className="company">
                    <img
                        className={`comp-logo`}
                        src={competitor.competitorLogo.file.url}
                        alt={competitor.competitorLogo.title}
                    />
                    <div className="comp-link-wrap">
                        <p className="comp-link">Compare {competitor.name} vs. Stitch</p>
                    </div>
                </span>
            </Link>
        );
    };

    return (
        <section className="st-section">
            <div className="grid-container">
                <div className="companies-grid">
                    {/* Do not show Qlik Replicate (slug == "attunity") */}
                    {allCompetitors.map(
                        (competitor, index) =>
                            competitor.slug !== "attunity" && <CompetitorItem competitor={competitor} index={index} />
                    )}
                </div>
            </div>
        </section>
    );
};

export default CompetitorGrid;
