import Link from "../../../../src/components/misc/link";
import SectionSeparator from "../../../../src/components/layout/section-separator";
import PlanComparisonTable from "../../../../src/components/misc/plan-comparison-table";
import PricingCalculator from "../../../../src/components/misc/pricing-calculator";
import CTASection from "../../../../src/components/misc/cta-section";
import CTAButton from "../../../../src/components/misc/cta-button";
import FAQSection from "../../../../src/components/misc/faq-section";
import FAQColumn from "../../../../src/components/misc/faq-column";
import FAQQuestion from "../../../../src/components/misc/faq-question";
import * as React from 'react';
export default {
  Link,
  SectionSeparator,
  PlanComparisonTable,
  PricingCalculator,
  CTASection,
  CTAButton,
  FAQSection,
  FAQColumn,
  FAQQuestion,
  React
};