import React from "react";
import GoogleMap from "./google-map";
import MapAddressOverlay from "./google-map-address-overlay";

const MapSection = ({ containerClasses = null, overlay = false }) => {
    return (
        <section className={`${containerClasses} map`}>
            {overlay && <MapAddressOverlay />}
            <GoogleMap />
        </section>
    );
};

export default MapSection;
