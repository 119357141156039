import { navigate } from "gatsby";
import UTMPurser from "../purser/index";

const hiddenClass = "js-hidden";

export const formValidation = {
    // Initializes other methods which establish necessary variables, listeners and a default form state.
    init(allValidationMessages) {
        // Establish necessary variables
        formValidation.setupVars(allValidationMessages);

        // Establish required listeners
        formValidation.setupListeners();

        // Disable submit button by default
        formValidation.disableForm();
    },

    // Establishes necessary variables from the available DOM elements.
    setupVars(allValidationMessages) {
        this.validatonMessages = allValidationMessages;
        this.firstNameInput =
            document.querySelector("[id^=FirstName]") ||
            document.querySelector("[id^=firstname]") ||
            document.querySelector("[id^=firstName]");
        this.lastNameInput =
            document.querySelector("[id^=LastName]") ||
            document.querySelector("[id^=lastname]") ||
            document.querySelector("[id^=lastName]");
        this.emailInput =
            document.querySelector("[id^=Email]") ||
            document.querySelector("[id^=email]") ||
            document.querySelector("[type=email]");
        this.phoneNumberInput = document.querySelector("[id^=Phone]") || document.querySelector("[id^=phone]");
        this.submitButton = document.querySelector('[id*="mktoForm"] button[type="submit"]');
        this.allRequiredInputs = document.querySelectorAll('.mktoField.mktoRequired:not([type="hidden"])');
    },

    // Establishes event listeners for the "required" form elements.
    setupListeners() {
        for (let i = 0; i < this.allRequiredInputs.length; i++) {
            const inputEl = this.allRequiredInputs[i];
            // Whenever a field is changed, validate the form
            inputEl.addEventListener("change", formValidation.validate);
            inputEl.addEventListener("keyup", formValidation.validate);
        }
    },

    /**
     * Changes classes and places error message into DOM.
     * @param {object} inputEl The input element which has an erroneous value.
     * @param {string} message The error message to display.
     */
    showError(inputEl, message) {
        inputEl.classList.add("is-invalid");
        let errorEl = document.getElementById(inputEl.id + "-error");
        let errorMarkup = `<div id="${inputEl.id}-error" class="form-error mktoErrorMsg">${message}</div>`;

        if (!errorEl) {
            inputEl.insertAdjacentHTML("afterend", errorMarkup);
        }
    },

    /**
     * Removes the error markup from the DOM
     * @param {object} inputEl The input element from which the error should be removed.
     */
    hideError(inputEl) {
        inputEl.classList.remove("is-invalid");

        let errorEl = document.getElementById(inputEl.id + "-error");
        if (errorEl) errorEl.parentNode.removeChild(errorEl);
    },

    /**
     * Tests an input element's value against a particular RegEx
     * @param {object} inputEl The input element to test.
     * @param {string} errorMessage The error message to display if the test fails.
     * @param {string} testType The type of test – 'name' or 'phone'.
     */
    testField(inputEl, errorMessage, testType = "name") {
        /* eslint-disable no-useless-escape */
        const nameExp = /^[^0-9.,_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]*$/;
        const phoneExp = /^[0-9- ()+]+$/;
        const emailExp =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        /* eslint-enable no-useless-escape */
        let result = false;

        if (inputEl === null || inputEl === undefined) {
            // If the element isn't present, skip validation
            return true;
        }

        // If the field exists and has a value
        if (inputEl && inputEl.value) {
            // If testing for a name
            if (testType === "name") result = nameExp.test(inputEl.value);

            // If testing for a phone number
            if (testType === "phone" && inputEl.value.length >= 8 && inputEl.value.length <= 20)
                result = phoneExp.test(inputEl.value);

            // If testing for an email address
            // Will return before custom error message and will show Marketo's error message
            if (testType === "email") return emailExp.test(inputEl.value);

            // If the element doesn't pass the RegEx test
            if (result === false) {
                // Show the associated error message
                formValidation.showError(inputEl, errorMessage);

                // Ensure the field can be seen
                inputEl.parentElement.classList.remove("js-hidden");
            } else {
                // If the test result is good, hide the error
                formValidation.hideError(inputEl);
            }
        }

        return result;
    },

    // Reviews all present required fields (.mktoRequired)
    bulkReview() {
        let formComplete = true;
        for (let i = 0; i < this.allRequiredInputs.length; i++) {
            const reqEl = this.allRequiredInputs[i];

            // The types of inputs which have a "value"
            let inputTypesWithValues = ["text", "select-one", "select-multiple"];

            if (
                (inputTypesWithValues.indexOf(reqEl.type) !== -1 && reqEl.value === "") ||
                (reqEl.type === "checkbox" && reqEl.checked === false)
            ) {
                formComplete = false;
            }
        }
        return formComplete;
    },

    // Disables the submit button
    disableForm() {
        // Some times this is null
        // Also if there aren't any required inputs, then don't disable the button
        if (this.submitButton !== null) {
            this.submitButton.setAttribute("disabled", "disabled");
        }
    },

    /**
     * Primary Marketo form validation function
     * Sets error messages, performs custom validation of First Name, Last Name and Phone,
     * then changes the state of the submit button depending on the validation of the form's fields.
     */
    validate() {
        // Setting default English error messages
        let firstNameError = "First Name must not include<br>symbols or numbers.";
        let lastNameError = "Last Name must not include<br>symbols or numbers.";
        let phoneError = "Phone number is invalid. Must be 8 to 20 digits.";

        // Acquiring localised error strings, if available
        if (formValidation.validatonMessages.firstname_error)
            firstNameError = formValidation.validatonMessages.firstname_error;
        if (formValidation.validatonMessages.lastname_error)
            lastNameError = formValidation.validatonMessages.lastname_error;
        if (formValidation.validatonMessages.phone_error) phoneError = formValidation.validatonMessages.phone_error;

        // Begin "custom" validation of First Name, Last Name and Phone
        let validFirstName = formValidation.testField(formValidation.firstNameInput, firstNameError);
        let validLastName = formValidation.testField(formValidation.lastNameInput, lastNameError);
        let validEmail = formValidation.testField(formValidation.emailInput, null, "email");
        let validPhoneNumber = formValidation.testField(formValidation.phoneNumberInput, phoneError, "phone");

        // Review the required fields in bulk
        let bulkState = formValidation.bulkReview();

        // If the required fields are valid
        if (
            validFirstName === true &&
            validLastName === true &&
            validEmail === true &&
            validPhoneNumber === true &&
            bulkState === true
        ) {
            // Enable the submit button
            formValidation.submitButton.removeAttribute("disabled");
        } else {
            // Disable the submit button
            formValidation.disableForm();
        }
    },
};

export const destyleMktoForm = (mktoForm, options) => {
    const formEl = mktoForm.getFormElem()[0];
    const arrayFrom = Function.prototype.call.bind(Array.prototype.slice);
    options = options || {};

    // remove element styles from <form> and children
    if (!options.keepInline) {
        const styledEls = arrayFrom(formEl.querySelectorAll("[style]")).concat(formEl);
        styledEls.forEach(function (el) {
            el.removeAttribute("style");
        });

        formEl.querySelectorAll("style")[0].firstChild.data = "";
    }
    // disable remote stylesheets and local styles
    if (!options.keepSheets) {
        const styleSheets = arrayFrom(document.styleSheets);
        styleSheets.forEach(function (ss) {
            if (
                // eslint-disable-next-line no-undef
                [mktoForms2BaseStyle, mktoForms2ThemeStyle].indexOf(ss.ownerNode) !== -1 ||
                formEl.contains(ss.ownerNode)
            ) {
                ss.disabled = true;
            }
        });
    }

    if (!options.moreStyles) {
        formEl.setAttribute("data-styles-ready", "true");
    }
};

export const applyCheckboxClasses = () => {
    // Adding class to field wrapper elements when checkbox is present
    let checkWrappers = document.getElementsByClassName("mktoCheckboxList");
    for (let i = 0; i < checkWrappers.length; i++) {
        const checkEl = checkWrappers[i];
        checkEl.parentNode.classList.add("js-has-checkbox");
    }
};

/**
 * Form Experiment Fill
 * When the Optimizely object is available, this function places Optimizely-specific
 * info into specific hidden Marketo fields.
 */

export const formExperimentFill = () => {
    // If an Optimizely object exists and is fully featured
    if (window.optimizely && window.optimizely.get) {
        // Acquire Optimizely 'state' object
        let optState = window.optimizely.get("state");

        // Acquire form fields to be populated
        let mktoExpField = document.getElementsByName("Optimizely_Experiment_Name__c")[0];
        let mktoVarField = document.getElementsByName("Optimizely_Variation_Name__c")[0];

        // Ensure Marketo fields are present
        if (mktoExpField && mktoVarField) {
            // Acquire the current experiment states
            let optExperiments = optState.getExperimentStates();

            let experimentName = "";
            let variationName = "";
            let i = 0;

            // Loop through present experiments
            for (let j in optExperiments) {
                const exp = optExperiments[j];

                if (i > 0) {
                    experimentName += " | ";
                    variationName += " | ";
                }

                // Experiment Name concatenation
                // The 'null' string value is intentional for sorting and filtering in Tableau
                exp["experimentName"] ? (experimentName += exp["experimentName"]) : (experimentName += "null");

                // Variation concatenation
                // The 'null' string value is intentional for sorting and filtering in Tableau
                exp["variation"] ? (variationName += exp["variation"]["name"]) : (variationName += "null");
                i = i + 1;
            }

            // Set Marketo fields with formatted values
            mktoExpField.value = experimentName;
            mktoVarField.value = variationName;
        }
    }
};

export const addPurserValuesToMarketoForms = (form) => {
    const purserValues = UTMPurser.get();

    let latestValues;

    // Visitor has been to the site before, so pull the values from the latest visit
    if (purserValues.hasOwnProperty("visits")) {
        latestValues = purserValues["visits"][purserValues["visits"].length - 1];
    } else {
        latestValues = purserValues;
    }

    const utmValues = Object.keys(latestValues)
        .filter((propertyName) => {
            return propertyName.indexOf("utm_") === 0;
        })
        .reduce((newData, propertyName) => {
            newData[propertyName] = latestValues[propertyName];
            return newData;
        }, {});

    const mapping = {
        utm_source: "CampaignSource",
        utm_medium: "CampaignMedium",
        utm_campaign: "CampaignID",
        utm_content: "CampaignContent",
        utm_term: "CampaignTerm",
    };

    const mappedValues = Object.keys(mapping).reduce((newData, key) => {
        if (utmValues.hasOwnProperty(key)) {
            newData[mapping[key]] = utmValues[key];
        }
        return newData;
    }, {});

    form.setValues(mappedValues);

    const formEl = form.getFormElem()[0];

    if (formEl.Entry_URL__c !== undefined) {
        form.setValues({ Entry_URL__c: latestValues.landing_page });
    } else {
        form.addHiddenFields({ Entry_URL__c: latestValues.landing_page });
    }

    if (formEl.sourceURL !== undefined) {
        form.setValues({ sourceURL: latestValues.referrer });
    } else {
        form.addHiddenFields({ sourceURL: latestValues.referrer });
    }
};

/**
 * ZoomInfo Integration
 * Object with methods primarily used to initialize ZoomInfo with Marketo forms.
 */
export const zoomInfoIntegration = {
    // Initilizes ZoomInfo configuration options and sets a fallback timer.
    init(form) {
        if (window._zi && window._zi.formId) {
            // Create <script> tag for integrating ZoomInfo snippet
            let zi = document.createElement("script");
            zi.type = "text/javascript";
            zi.async = true;
            zi.src = "//ws-assets.zoominfo.com/formcomplete.js";
            let zis = document.getElementsByTagName("script")[0];
            zis.parentNode.insertBefore(zi, zis);
        } else {
            // If no ZoomInfo object, show all fields and return
            zoomInfoIntegration.displayAllFields(form.getFormElem()[0]);
            return;
        }

        // Assign callback method to ZoomInfo object
        window._zi.callbacks = { onMatch: zoomInfoIntegration.onMatch };

        const fallbackTimeout = window._zi.fallbackTimeout || 2000;

        // Fallback for if/when ZoomInfo can't connect or fails
        setTimeout(() => {
            // eslint-disable-next-line no-undef
            let mainForm = MktoForms2.allForms()[0];
            let formEl = mainForm.getFormElem()[0];

            // If the data-zi-mapped-form data attribute is missing
            if (formEl.hasAttribute("data-zi-mapped-form") === false) {
                // ZoomInfo has not loaded
                formValidation.zoomLoaded = false;
                zoomInfoIntegration.displayAllFields(formEl);
            }
        }, fallbackTimeout);

        zoomInfoIntegration.showForm(form);
    },

    // Display ALL of the form fields, carte-blanche
    displayAllFields(formEl) {
        // Add class for revealing all fields
        formEl.classList.add("js-show-all-fields");

        // Remove the hiddenClass from the associated fields, revealing them to the user
        const fieldWraps = document.querySelectorAll("form.js-show-all-fields .mktoFieldWrap");
        for (let i = 0; i < fieldWraps.length; i++) {
            const wrapEl = fieldWraps[i];
            wrapEl.classList.remove(hiddenClass);
        }

        formEl.classList.add("js-form-loaded");
    },

    // Hides all 'text' and 'select' form fields, then displays the form
    showForm(form) {
        const hiddenFieldTypes = ["text", "select-one", "tel"];
        const mktoFields = document.getElementsByClassName("mktoField");

        for (let i = 0; i < mktoFields.length; i = i + 1) {
            const fieldEl = mktoFields[i];

            // If the field's type is in the hiddenFieldTypes array, hide it
            if (hiddenFieldTypes.includes(fieldEl.type)) {
                fieldEl.parentElement.classList.add(hiddenClass);

                // If the field is 'country', and showCountry flag is set to 'true'
                if (window._zi.showCountry && fieldEl.name.toString().toLowerCase() === "country") {
                    // Show the country field
                    fieldEl.parentElement.classList.remove(hiddenClass);
                }
            }
        }

        // Show form once it's loaded and the fields are appropriately displayed
        form.getFormElem()[0].classList.add("js-form-loaded");
    },

    // Hides or shows fields based on when a ZoomInfo match is found
    onMatch(matchResult) {
        const mktoFields = document.getElementsByClassName("mktoField");

        // Loop through all Marketo fields
        for (let i = 0; i < mktoFields.length; i = i + 1) {
            const fieldEl = mktoFields[i];

            // 1) If the field is not part of the matched set,
            // 2) or it has no selected value (ie: a <select> field)
            // 3) or it's the email field
            if (!matchResult[fieldEl.name] || fieldEl.value === "" || fieldEl.type === "email") {
                // Show the field
                fieldEl.parentElement.classList.remove(hiddenClass);
            } else {
                // Hide the field
                fieldEl.parentElement.classList.add(hiddenClass);
            }
        }

        // Run form validation against the currently available data
        formValidation.validate();
    },
};

/**
 * Form onSuccess Callbacks
 */

export const trustAssessor = (form) => {
    form.onSuccess((values, followUpUrl) => {
        const email = values.Email;
        const ENDPOINT = "https://assessor.trustscore.talend.com/user/register";
        const FRONTEND = "https://assessor.trustscore.talend.com/saveuser/";
        const STORAGE_KEY = "@talend-trust-assessor";

        const onRegisterSuccess = (response) => {
            if (oReq.status === 200) {
                localStorage.setItem(STORAGE_KEY, JSON.parse(oReq.responseText).unique_id);
                window.location.href = FRONTEND + JSON.parse(oReq.responseText).unique_id;
            }
        };

        let oReq = new XMLHttpRequest();
        oReq.onload = onRegisterSuccess;
        oReq.open("POST", ENDPOINT, true);
        oReq.send(JSON.stringify({ email: email, responses: values }));

        // Return false to prevent the submission handler continuing with its own processing
        return false;
    });
};

export const stitchSignUp = (form) => {
    form.onSuccess((values, followUpUrl) => {
        const vals = form.vals();
        window.location.href = "https://app.stitchdata.com/signup?email=" + vals.Email;
        return false;
    });
};

export const formRedirect = (form, redirectionUrl, formConversionType) => {
    form.onSuccess((values, followUpUrl) => {
        window.location.href = redirectionUrl + "?ty=" + formConversionType;
        return false;
    });
};

export const normalFormSubmission = (form, thankYouMessage, formConversionType) => {
    form.onSuccess((values, followUpUrl) => {
        const id = form.getId();

        // Hide the form
        form.getFormElem().hide();

        // Thank you message display needs some loves ...
        const formContainer = document.querySelector(".formHolder.form-id-" + id);

        // Remove all child nodes in the form container
        let child = formContainer.lastElementChild;
        while (child) {
            formContainer.removeChild(child);
            child = formContainer.lastElementChild;
        }

        // Add span tag with class='thank-you-msg' with the thank you text
        if (document.querySelectorAll("span.thank-you-msg").length === 0) {
            let thankYou = document.createElement("span");
            thankYou.classList.add("thank-you-msg");
            thankYou.innerHTML = thankYouMessage;
            formContainer.appendChild(thankYou);
        }

        // Add Tracking Param to URL
        // This will navigate to a new location and take
        // care of all the GA events and such
        navigate(window.location.pathname + "?ty=" + formConversionType, { replace: true });

        return false;
    });
};

export const formVideoModal = (form, videoSrc) => {
    form.onSuccess((values, followUpUrl) => {
        const id = form.getId();
        const formContainer = document.querySelector(".formHolder.form-id-" + id);
        const modalContent = videoModal(videoSrc);
        const modal = new Modal(formContainer, modalContent);
        modal.openModal();
        return false;
    });
};

const videoModal = (videoSrc) => {
    return `
    <div class="st-modal" id="videoModal">
        <div class="content">
            <input type="checkbox" id="nav-toggle" class="nav-toggle" />
            <label for="nav-toggle" id="nav-trigger" class="nav-trigger" tabindex="1" accesskey="n" role="button" aria-pressed="false">
                <span>Close Modal Window</span>
            </label>
            <h2 class="st-heading--3">Want to try it out for yourself? <a href="https://app.stitchdata.com/signup" target="_blank">Start your Free Trial today!</a></h2>
            <h2 class="st-heading--3">Want to talk with one of our data experts? <a href="/watch-demo/thanks">Request a call-back now!</a></h2>
            <div class="video-holder">
                <iframe src="${videoSrc}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
        </div>
    </div>`;
};

class Modal {
    constructor(formContainer, modalContent) {
        this.formContainer = formContainer;
        this.formContainer.innerHTML += modalContent;
        this.modalEl = document.querySelector("#videoModal");
        this.listenForClick();
        this.listenForESC();
    }

    openModal = () => {
        this.modalEl.classList.add("is-open");
    };

    closeModal = () => {
        this.modalEl.classList.remove("is-open");
        this.modalEl.remove();
    };

    listenForClick = () => {
        document.querySelector(".st-modal input.nav-toggle").addEventListener("click", () => {
            this.closeModal();
        });
        document.querySelector(".st-modal label.nav-trigger").addEventListener("click", () => {
            this.closeModal();
        });
    };

    listenForESC = () => {
        document.addEventListener("keyup", (e) => {
            if (e.defaultPrevented) {
                return;
            }

            let key = e.key || e.keyCode;

            if (key === "Escape" || key === "Esc" || key === 27) {
                this.closeModal();
            }
        });
    };
}
