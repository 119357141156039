import Link from "../../../../src/components/misc/link";
import CTASection from "../../../../src/components/misc/cta-section";
import CTAButton from "../../../../src/components/misc/cta-button";
import MapSection from "../../../../src/components/misc/map-section";
import SectionSeparator from "../../../../src/components/layout/section-separator";
import AssetValues from "../../../../src/assets/images/illustrations/asset-values.svg";
import AssetPressIcon from "../../../../src/assets/svg/icon-company-press.svg";
import AssetCompanyLogos from "../../../../src/assets/svg/icon-company-logos.svg";
import AssetBlogIcon from "../../../../src/assets/svg/icon-blog.svg";
import * as React from 'react';
export default {
  Link,
  CTASection,
  CTAButton,
  MapSection,
  SectionSeparator,
  AssetValues,
  AssetPressIcon,
  AssetCompanyLogos,
  AssetBlogIcon,
  React
};