import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const GlossaryList = ({ heading, subHeading }) => {
    const { entries } = useStaticQuery(graphql`
        query contentfulGlossaryEntriesQuery {
            entries: allContentfulGlossaryEntry {
                nodes {
                    id
                    slug
                    glossary_term_name
                }
            }
        }
    `);

    const unique = entries.nodes
        .filter((v, i, a) => a.findIndex((t) => t.slug === v.slug) === i)
        .sort((a, b) => a.glossary_term_name.localeCompare(b.glossary_term_name));

    return (
        <section className="st-section glossary">
            <h3 className="st-heading--2">{heading}</h3>
            <p className="st-subheading">{subHeading}</p>
            <ul className="glossary-list">
                {unique.map((entry) => (
                    <li key={entry.id}>
                        <Link to={`/resources/glossary/${entry.slug}`}>{entry.glossary_term_name}</Link>
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default GlossaryList;
