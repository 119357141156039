import React from "react";
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";
import scrollToSection from "../../utils/scrollToElement";
import URI from "urijs";

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, ...other }) => {
    // Get current Location Object from the router
    const location = useLocation();

    // Create URI object from "to" for convenience (access to query string, fragment, etc).
    let uri = URI(to ?? "#");

    const pageList = useStaticQuery(graphql`
        query {
            pageList {
                id
                internal {
                    content
                }
            }
        }
    `);

    const pageListData = JSON.parse(pageList.pageList.internal.content ?? "[]");

    // Variable to track whether this is a link back to stitch-www
    const linkToNewStitch = pageListData.includes(to);

    // Open in a new window for any link that goes off the stitchdata domain.
    const external = RegExp("^((f|ht)tps?:)?//(?!" + location.host + "|www.stitchdata.com)").test(to);

    // Use Gatsby Link for internal non-jump links, and standard anchor tag for everything else.
    if (!linkToNewStitch && !external && (to && !to.startsWith("#")) && !uri.fragment() && uri.protocol() !== "mailto" && uri.protocol() !== "tel") {
        return (
            <GatsbyLink to={to} activeClassName={activeClassName} {...other}>
                {children}
            </GatsbyLink>
        );
    }

    // If link is a jump-link, scroll to the element.
    let jumpLinkClickHandler;
    if (to && to.startsWith("#") && to !== "#no-anchor") {
        jumpLinkClickHandler = (e) => {
            e.preventDefault();

            scrollToSection(to);
        };

        return (
            <a href={to} onClick={jumpLinkClickHandler} {...other}>
                {children}
            </a>
        );
    }

    // Get attributes ready to spread if link should open in a new window.
    const newWindowAttributes = external && !linkToNewStitch ? { rel: "noopener noreferrer", target: "_blank" } : {};

    return (
        <a href={to} {...other} {...newWindowAttributes}>
            {children}
        </a>
    );
};

export default Link;
