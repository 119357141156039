import Link from "../../../../src/components/misc/link";
import CTASection from "../../../../src/components/misc/cta-section";
import AssetGrowth from "../../../../src/assets/images/illustrations/report-growth-icon.svg";
import AssetIndustries from "../../../../src/assets/images/illustrations/report-industries-icon.svg";
import AssetSkills from "../../../../src/assets/images/illustrations/report-skills-icon.svg";
import AssetEducation from "../../../../src/assets/images/illustrations/report-education-icon.svg";
import AssetBackground from "../../../../src/assets/images/illustrations/report-background-icon.svg";
import AssetWhereabouts from "../../../../src/assets/images/illustrations/report-whereabouts-icon.svg";
import * as React from 'react';
export default {
  Link,
  CTASection,
  AssetGrowth,
  AssetIndustries,
  AssetSkills,
  AssetEducation,
  AssetBackground,
  AssetWhereabouts,
  React
};