import React from "react";
import Link from "./link";

const CTAButton = ({ ctaClasses, ctaLink, ctaLabel }) => {
    return (
        <Link to={ctaLink} className={ctaClasses}>
            {ctaLabel}
        </Link>
    );
};

export default CTAButton;
