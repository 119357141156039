import React from "react";

const FAQSection = ({ faqClasses, headLine, children }) => {
    return (
        <section className={`st-section ${faqClasses}`}>
            <div className="st-feature">
                <h2 className="st-heading--2">{headLine}</h2>
                <div className="faq-columns">{children}</div>
            </div>
        </section>
    );
};

export default FAQSection;
