import SectionSeparator from "../../../../src/components/layout/section-separator";
import CTASection from "../../../../src/components/misc/cta-section";
import AnalysisToolsGrid from "../../../../src/components/analysis-tools/analysis-tools-grid";
import Link from "../../../../src/components/misc/link";
import integrationIcon from "../../../../src/assets/images/icons/icon-tool-specific-integration.svg";
import fileIcon from "../../../../src/assets/images/icons/icon-file.svg";
import visibilityIcon from "../../../../src/assets/images/icons/icon-visibility.svg";
import assetSources from "../../../../src/assets/images/illustrations/asset-sources.svg";
import assetDestinations from "../../../../src/assets/images/illustrations/asset-destinations.svg";
import * as React from 'react';
export default {
  SectionSeparator,
  CTASection,
  AnalysisToolsGrid,
  Link,
  integrationIcon,
  fileIcon,
  visibilityIcon,
  assetSources,
  assetDestinations,
  React
};