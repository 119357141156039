import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";

const CustomerQuoteSlider = () => {
    const [activeSlide, setActiveSlide] = useState(0);
    const { customers } = useStaticQuery(graphql`
        query QuoteQuery {
            customers: allContentfulCustomer(
                filter: { case_study: { hidden: { eq: null } }, short_quote: { short_quote: { ne: null } } }
            ) {
                nodes {
                    id
                    company_name
                    logo {
                        file {
                            url
                        }
                    }
                    quote_name
                    quote_title
                    short_quote {
                        short_quote
                    }
                }
            }
        }
    `);

    const allCustomers = customers.nodes;

    const settings = {
        centerMode: true,
        className: "st-slider",
        variableWidth: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    variableWidth: false,
                    arrows: false,
                },
            },
        ],
        afterChange: (current) => setActiveSlide(current),
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return <button className={className} style={{ ...style }} onClick={onClick} aria-label="Next Slide" />;
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return <button className={className} style={{ ...style }} onClick={onClick} aria-label="Previous Slide" />;
    }

    return (
        <>
            <section className="st-section" id="quote-carousel">
                <div className="st-feature">
                    <h2 className="st-heading--2">Why Stitch?</h2>
                </div>
            </section>
            <Slider {...settings}>
                {allCustomers.map((cust) => (
                    <div className="slide-item" key={cust.id}>
                        <img src={cust.logo.file.url} alt={`${cust.company_name}`} height="70" />
                    </div>
                ))}
            </Slider>
            <section className="st-section">
                <div className="chosen-quote">
                    <p className="st-subheading">&ldquo;{allCustomers[activeSlide].short_quote.short_quote}&rdquo;</p>
                    <h3 className="st-heading--4">
                        {allCustomers[activeSlide].quote_name + ", " + allCustomers[activeSlide].quote_title}
                    </h3>
                </div>
            </section>
        </>
    );
};

export default CustomerQuoteSlider;
