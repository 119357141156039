import Link from "../../../../src/components/misc/link";
import CTASection from "../../../../src/components/misc/cta-section";
import etl_extract from "../../../../src/assets/images/illustrations/asset-etl-extract.png";
import etl_transform from "../../../../src/assets/images/illustrations/asset-etl-transform.png";
import etl_load from "../../../../src/assets/images/illustrations/asset-etl-load.png";
import * as React from 'react';
export default {
  Link,
  CTASection,
  etl_extract,
  etl_transform,
  etl_load,
  React
};